import React, { FC } from 'react';

import { QuantitySelectorProps } from './QuantitySelector.types';

const QuantitySelector: FC<QuantitySelectorProps> = ({ setQuantity, quantity, stock }) => {

    const updateQuantity = (increase: boolean) => {
        if (!increase && quantity === 1) return;
        if (increase && quantity >= stock) return;
        const newQuantity = increase ? quantity + 1 : quantity - 1;
        setQuantity(newQuantity);
    };

    return (
        <div className="quantity-selector">
            <button onClick={() => updateQuantity(false)}>
                <span>-</span>
            </button>
            <span>{quantity}</span>
            <button onClick={() => updateQuantity(true)}>
                <span>+</span>
            </button>
        </div>
    )
};

export { QuantitySelector };
