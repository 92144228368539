import React, { FC, useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import { bookingActions } from 'sagas/booking/booking';
import { bookingAddActions } from 'sagas/booking/booking-add';
import { bookingAppointmentsActions } from 'sagas/booking/booking-appointments';
import {
    BookingAppointmentsRequestAction,
    BookingAppointmentsResetAction,
    BookingAddRequestAction,
    BookingAddActionTypes,
} from 'sagas/booking/booking.types';

import { authActions } from 'sagas/auth/auth';
import { AuthRequestAction } from 'sagas/auth/auth.types';

import { selectLanding, selectBooking, selectAuth } from 'sagas/selectors';
import { useLoaderSelector } from 'hooks/use-loader-selector';
import { useErrorSelector } from 'hooks/use-error-selector';

import { BarberModalProps, BarberModalFormValues } from './BarberModal.types';
import { Barber, Service } from 'resources/types';
import { ButtonTypes } from 'enums/Button.enums';
import { ErrorSizeTypes } from 'enums/Error.enums';

import { dispatchWithPromise } from 'utils/dispatch-with-promise';
import { validateDate } from 'utils/validate-date';

import { Button } from 'components/atoms/button/Button';
import { Error } from 'components/atoms/error/Error';
import { HighlightedWord } from 'components/atoms/highlighted-word/HighlightedWord';
import { FormGroup } from 'components/molecules/form-group/FormGroup';
import { SuccessPrompt } from 'components/molecules/success-prompt/SuccessPrompt';
import { Modal } from 'components/templates/modal/Modal';

const DAYS = ['E Diele', 'E Hënë', 'E Martë', 'E Merkurë', 'E Enjte', 'E Premte', 'E Shtunë'];
const MONTHS = [
    'Janar',
    'Shkurt',
    'Mars',
    'Prill',
    'Maj',
    'Qershor',
    'Korrik',
    'Gusht',
    'Shtator',
    'Tetor',
    'Nentor',
    'Dhjetor',
];

const BLOCKED_SLOTS: Record<Barber['name'], string[]> = {
    'Kushtrim Sadiku': ['13:00', '13:30', '19:00'],
};

const BarberModal: FC<BarberModalProps> = ({ barber, onClose }) => {
    const [finished, setFinished] = useState<boolean>(false);
    const [review, setReview] = useState<boolean>(false);
    const [selectedServices, setSelectedServices] = useState<Service[]>([]);
    const [activeBarber, setActiveBarber] = useState<Barber>();
    const [timeframe, setTimeframe] = useState<{ date: string; time: string }>({
        date: '',
        time: '',
    });
    const [selectedServicesError, setSelectedServicesError] = useState<{
        service: { message: string };
    }>({ service: { message: '' } });
    const dispatch = useDispatch();
    const [isLoading] = useLoaderSelector(BookingAddActionTypes.REQUEST);
    const [isError] = useErrorSelector(BookingAddActionTypes.FAILURE);

    const { barbers } = useSelector(selectLanding);
    const { appointments } = useSelector(selectBooking);
    const { user } = useSelector(selectAuth);

    const { register, handleSubmit, errors, setError, clearErrors, trigger, getValues } =
        useForm<BarberModalFormValues>({
            mode: 'onTouched',
            defaultValues: {
                name: user?.name || '',
                phone: user?.phone || '',
                email: user?.email || '',
            },
        });

    useEffect(() => {
        setActiveBarber(barber);
    }, [barber]);

    const onModalClose = () => {
        dispatchWithPromise<BookingAppointmentsResetAction>(
            dispatch,
            bookingActions.appointmentsReset,
            null,
        );
        onClose();
    };

    const updateSelectedServices = (service: Service, action: 'add' | 'remove') => {
        if (action === 'add' && !selectedServices.includes(service)) {
            setSelectedServices([...selectedServices, service]);
            setSelectedServicesError({ service: { message: '' } });
        } else if (action === 'remove') {
            setSelectedServices(selectedServices.filter(s => s !== service));
            if (!(selectedServices.length - 1))
                setSelectedServicesError({ service: { message: 'Must select service' } });
        }
    };

    const onSubmit: SubmitHandler<BarberModalFormValues> = values => {
        const serviceIds = selectedServices.map(service => service.id);
        setTimeframe({ date: values.date, time: values.time });

        dispatchWithPromise<AuthRequestAction>(dispatch, authActions.request, {
            name: values.name,
            phone: values.phone,
            email: values.email || undefined,
        }).then(() => {
            dispatchWithPromise<BookingAddRequestAction>(dispatch, bookingAddActions.request, {
                barberId: parseInt(values.barber),
                serviceIds,
                date: values.date,
                time: values.time,
            })
                .then(() => {
                    setFinished(true);
                })
                .catch(e => console.log(e));
        });
    };

    const onBarberChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedBarber = barbers.filter(b => b.id === parseInt(e.target.value))[0];
        setActiveBarber(selectedBarber);
        setSelectedServices([]);

        if (getValues().date.length) {
            dispatchWithPromise<BookingAppointmentsRequestAction>(
                dispatch,
                bookingAppointmentsActions.request,
                {
                    date: getValues().date,
                    barberId: selectedBarber!.id,
                },
            );
        }
    };

    const onDateChange = (e: React.FormEvent<HTMLInputElement>) => {
        const valid = validateDate(e.currentTarget.value);
        if (typeof valid !== 'string')
            dispatchWithPromise<BookingAppointmentsRequestAction>(
                dispatch,
                bookingAppointmentsActions.request,
                {
                    date: e.currentTarget.value,
                    barberId: activeBarber!.id,
                },
            ).then((res: any) => {
                if (!res.availableAppointments?.length)
                    setError('time', {
                        type: 'manual',
                        message: 'Nuk ka termine të lira',
                    });
                else clearErrors('time');
            });
    };

    const onNextClick = async () => {
        const isValid = await trigger();

        if (!selectedServices.length)
            setSelectedServicesError({ service: { message: 'Duhet të zgjedhni servis' } });
        if (isValid && selectedServices.length) {
            setSelectedServicesError({ service: { message: '' } });
            setReview(true);
        }
    };

    const generateFormattedDate = (date: string, time: string) => {
        return `${DAYS[moment(date).day()]}, ${moment(date).tz('Europe/Belgrade').format('D')} ${
            MONTHS[moment(date).month()]
        } ${moment(date).tz('Europe/Belgrade').format('yyyy')} - ${time}`;
    };

    const calculatePrice = (): number =>
        selectedServices.reduce((total, num) => total + num.price, 0);

    return (
        <Modal onClose={onModalClose}>
            {finished ? (
                <SuccessPrompt to="/products" label="Shiko produktet">
                    <div>
                        <p>
                            Data e rezervimit:{' '}
                            <HighlightedWord
                                text={generateFormattedDate(timeframe.date, timeframe.time)}
                            />{' '}
                            me{' '}
                            <HighlightedWord text={activeBarber?.name.split(' ')[0] + 'in' || ''} />
                        </p>
                        <p style={{ marginTop: '1.5rem' }}>
                            Ju lusim të bëni screenshot këtë faqe për arsye dëshmie të rezervimit :)
                        </p>
                    </div>
                </SuccessPrompt>
            ) : (
                activeBarber && (
                    <div className="barber-modal">
                        <div className="barber-modal__picture">
                            <img src={activeBarber.imgUrl} alt="Barber" />
                        </div>
                        <div className="barber-modal__entries">
                            <form
                                className="barber-modal__entry-container barber-modal__entry-container--form"
                                style={{ display: review ? 'none' : 'block' }}
                            >
                                <div className="barber-modal__entry-group">
                                    <h4 className="barber-modal__heading">Bëj termin</h4>

                                    <div className="barber-modal__form-group-cont">
                                        <FormGroup
                                            label="Berberi"
                                            name="barber"
                                            type="select"
                                            ref={register}
                                            selectedOption={activeBarber.id}
                                            options={barbers.map(barber => ({
                                                value: barber.id,
                                                label: barber.name,
                                            }))}
                                            onChange={onBarberChange}
                                            errors={errors}
                                        />
                                        <FormGroup
                                            label="Serviset"
                                            name="service"
                                            type="service"
                                            services={activeBarber.services}
                                            selectedServices={selectedServices}
                                            updateSelectedServices={updateSelectedServices}
                                            errors={selectedServicesError}
                                        />
                                        <FormGroup
                                            label="Data"
                                            name="date"
                                            type="date"
                                            ref={register({
                                                required: 'Data është e nevojshme',
                                                validate: value => validateDate(value),
                                            })}
                                            onChange={onDateChange}
                                            errors={errors}
                                        />
                                        <FormGroup
                                            label="Koha"
                                            name="time"
                                            type="select"
                                            options={appointments
                                                .filter(
                                                    slot =>
                                                        !BLOCKED_SLOTS[activeBarber.name]?.includes(
                                                            slot,
                                                        ),
                                                )
                                                .map(appointment => ({
                                                    value: appointment,
                                                    label: appointment,
                                                }))}
                                            ref={register({ required: 'Koha është e nevojshme' })}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <div className="barber-modal__entry-group">
                                    <h4 className="barber-modal__heading">Informacioni juaj</h4>

                                    <div className="barber-modal__form-group-cont">
                                        <FormGroup
                                            label="Emri i plote"
                                            name="name"
                                            type="text"
                                            ref={register({ required: 'Emri është i nevojshem' })}
                                            errors={errors}
                                        />
                                        <FormGroup
                                            label="Numri i telefonit"
                                            name="phone"
                                            type="text"
                                            ref={register({
                                                required: 'Numri i telefonit është i nevojshëm',
                                                minLength: {
                                                    value: 9,
                                                    message:
                                                        'Numri duhet të jete mes 9 dhe 13 karaktereve',
                                                },
                                                maxLength: {
                                                    value: 13,
                                                    message:
                                                        'Numri duhet të jete mes 9 dhe 13 karaktereve',
                                                },
                                            })}
                                            errors={errors}
                                        />
                                        <FormGroup
                                            label="Email (Opsionale)"
                                            name="email"
                                            type="email"
                                            ref={register({
                                                pattern: {
                                                    value: /^\S+@\S+$/i,
                                                    message: 'Email është e pasaktë',
                                                },
                                            })}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                                <Button type={ButtonTypes.primary} onClick={onNextClick}>
                                    Vazhdo
                                </Button>
                            </form>

                            {review && (
                                <div className="barber-modal__entry-container barber-modal__entry-container--review">
                                    <div className="barber-modal__entry-group">
                                        <h4 className="barber-modal__heading barber-modal__heading--review">
                                            Informacioni juaj
                                        </h4>

                                        <div className="barber-modal__info">
                                            <p>{getValues().name}</p>
                                            <p>{getValues().email || ''}</p>
                                            <p>{getValues().phone}</p>
                                        </div>
                                    </div>

                                    <div className="barber-modal__entry-group">
                                        <h4 className="barber-modal__heading barber-modal__heading--review">
                                            Rishiko rezervimin
                                        </h4>

                                        <div className="barber-modal__info">
                                            <h6 className="barber-modal__info-heading">
                                                Data dhe koha
                                            </h6>
                                            <p>
                                                {generateFormattedDate(
                                                    getValues().date,
                                                    getValues().time,
                                                )}
                                            </p>
                                        </div>

                                        <div className="barber-modal__info">
                                            <h6 className="barber-modal__info-heading">Serviset</h6>
                                            {selectedServices.map((item, index) => (
                                                <p key={`${item.title}-${index}`}>
                                                    {item.title} <span>{item.price}EU</span>
                                                </p>
                                            ))}
                                            <p className="barber-modal__total">
                                                Totali <span>{calculatePrice()}EU</span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="barber-modal__btn-group">
                                        <Button
                                            type={ButtonTypes.primary}
                                            onClick={handleSubmit(onSubmit)}
                                        >
                                            {!isLoading ? 'Rezervo' : 'Përpunuar...'}
                                        </Button>
                                        <Button
                                            type={ButtonTypes.outlinedLight}
                                            onClick={() => setReview(false)}
                                        >
                                            Kthehu
                                        </Button>
                                    </div>
                                    {isError && (
                                        <Error
                                            size={ErrorSizeTypes.medium}
                                            message="Termini nuk ka mundur te rezervohet"
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )
            )}
        </Modal>
    );
};

export { BarberModal };
