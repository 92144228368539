import { takeLatest, put } from 'redux-saga/effects';

import { axios } from 'utils/axios';
import { actionCreator } from 'utils/action-creator';
import { ProductsActions, ProductsActionTypes, ProductsFailureAction, ProductsInitState, ProductsRequestAction, ProductsSuccessAction } from './products.types';
import { Product } from 'resources/types';

/**
 * Redux Actions
 */
export const productsActions = {
    request: actionCreator<ProductsRequestAction>(ProductsActionTypes.REQUEST),
    success: actionCreator<ProductsSuccessAction>(ProductsActionTypes.SUCCESS),
    failure: actionCreator<ProductsFailureAction>(ProductsActionTypes.FAILURE)
};

/**
 * Init State
 */
export const initState: ProductsInitState = {
    items: [{
        id: 0,
        title: '',
        brand: '',
        description: '',
        price: 0,
        stock: 0,
        imgUrl: '',
        createdAt: '',
        updatedAt: '',
        pinned: false
    }],
    total: 0
}

/**
 * Default Reducer
 *
 * @param state
 * @param action
 */
export const productsReducer = (state = initState, action: ProductsActions) => {
    switch (action.type) {
        case ProductsActionTypes.SUCCESS:
            return { ...state, items: action.payload.items, total: action.payload.total }

        default:
            return state;
    }
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ payload, resolve, reject }: ProductsRequestAction) {
        try {
            const res = yield axios.get<{ results: Product[], total: number }>(`/products?page=${payload.page}`);
            yield put(productsActions.success({ items: res.data.results, total: res.data.total }));
            resolve!(res.data);
        } catch (err) {
            yield put(productsActions.failure(err.response.data.errors));
            reject!(err);
        }
    }
}

/**
 * Saga Watchers
 */
export const productsWatchers = function* () {
    yield takeLatest(ProductsActionTypes.REQUEST, sagas.request)
}
