import React, { FC } from 'react';

import { LogoProps } from './Logo.types';
import { LogoTypes } from 'enums/Logo.enums';

import LogoBlack from 'static/images/logo-black.png';
import LogoWhite from 'static/images/logo-white.png';

const Logo: FC<LogoProps> = ({ type = LogoTypes.black }) => {
    return (
        <div className="logo">
            {type === LogoTypes.black && <img src={LogoBlack} alt="Logo black" />}
            {type === LogoTypes.white && <img src={LogoWhite} alt="Logo white" />}
        </div>
    )
};

export { Logo };