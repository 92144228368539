import React, { useRef, useState } from 'react';
import gsap from 'gsap';

import { GalleryImageStyling } from './Gallery.types';

import GalleryButtonLeft from 'static/svgs/gallery-button-left.svg';
import GalleryButtonRight from 'static/svgs/gallery-button-right.svg';
import Gallery1 from 'static/images/gallery-1.jpg';
import Gallery2 from 'static/images/gallery-2.jpg';
import Gallery3 from 'static/images/gallery-3.jpg';
import Gallery4 from 'static/images/gallery-4.jpg';
import Gallery5 from 'static/images/gallery-5.jpg';
import Gallery6 from 'static/images/gallery-6.jpg';
import Gallery7 from 'static/images/gallery-7.jpg';
import Gallery8 from 'static/images/gallery-8.jpg';
import Gallery9 from 'static/images/gallery-9.jpg';
import Gallery10 from 'static/images/gallery-10.jpg';

const items = [Gallery1, Gallery2, Gallery3, Gallery4, Gallery5, Gallery6, Gallery7, Gallery8, Gallery9, Gallery10];

const Gallery = () => {
    const galleryContentRef = useRef<HTMLDivElement>(null);
    const [itemIndex, setItemIndex] = useState<number>(0);

    const imageStyling: GalleryImageStyling = {
        backgroundImage: `linear-gradient(rgba(0,0,0, .15),rgba(0,0,0, .15)), url(${Gallery1})`
    }

    const onClick = (side: 'left' | 'right') => {
        const newItemIndex = side === 'right'
            ? itemIndex === items.length - 1 ? 0 : itemIndex + 1
            : itemIndex === 0 ? items.length - 1 : itemIndex - 1;


        const tl = gsap.timeline();
        tl
            .to(galleryContentRef.current, {
                autoAlpha: 0,
                duration: .15,
                backgroundImage: `linear-gradient(rgba(0,0,0, .15),rgba(0,0,0, .15)), url(${items[newItemIndex]})`,
            })
            .add(() => setItemIndex(newItemIndex))
            .to(galleryContentRef.current, {
                duration: .15,
                autoAlpha: 1
            });
    }

    return (
        <div className="gallery">
            <div className="gallery__content" ref={galleryContentRef} style={imageStyling}>
                <div className="gallery__button-group">
                    <button onClick={() => onClick('left')} className="gallery__button">
                        <img src={GalleryButtonLeft} alt="Gallery button left" />
                    </button>
                    <button onClick={() => onClick('right')} className="gallery__button">
                        <img src={GalleryButtonRight} alt="Gallery button right" />
                    </button>
                </div>

                <p className="gallery__counter">{('0' + itemIndex).slice(-2)}&mdash;{('0' + (itemIndex + 1)).slice(-2)}’</p>
            </div>
        </div>
    )
};

export { Gallery };