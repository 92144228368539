import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, SubmitHandler } from 'react-hook-form';

import { useLoaderSelector } from 'hooks/use-loader-selector';
import { useErrorSelector } from 'hooks/use-error-selector';

import { selectShoppingBag, selectAuth } from 'sagas/selectors';
import { authActions } from 'sagas/auth/auth';
import { AuthRequestAction } from 'sagas/auth/auth.types';
import { shoppingBagReadActions } from 'sagas/shopping-bag/shopping-bag-read';
import { shoppingBagCompleteActions } from 'sagas/shopping-bag/shopping-bag-complete';
import {
    ShoppingBagReadRequestAction,
    ShoppingBagReadItemsCountAction,
    ShoppingBagReadActionTypes,
    ShoppingBagCompleteRequestAction,
    ShoppingBagCompleteActionTypes,
} from 'sagas/shopping-bag/shopping-bag.types';

import { dispatchWithPromise } from 'utils/dispatch-with-promise';

import { ShoppingBagFormValues } from './ShoppingBag.types';
import { ButtonTypes } from 'enums/Button.enums';
import { ContainerEnums } from 'enums/Container.enums';

import { Button } from 'components/atoms/button/Button';
import { Error } from 'components/atoms/error/Error';
import { FormGroup } from 'components/molecules/form-group/FormGroup';
import { ShoppingBagProduct } from 'components/molecules/shopping-bag-product/ShoppingBagProduct';
import { Container } from 'components/templates/container/Container';
import { Nav } from 'components/organisms/nav/Nav';
import { Footer } from 'components/templates/footer/Footer';
import { Modal } from 'components/templates/modal/Modal';
import { SuccessPrompt } from 'components/molecules/success-prompt/SuccessPrompt';
import { Loader } from 'components/molecules/loader/Loader';

import TooltipIcon from 'static/svgs/tooltip-icon.svg';
import { ErrorSizeTypes } from 'enums/Error.enums';

const ShoppingBag = () => {
    const [empty, setEmpty] = useState<boolean>(false);
    const [finished, setFinished] = useState<boolean>(false);
    const [isReadLoading] = useLoaderSelector(ShoppingBagReadActionTypes.REQUEST);
    const [isCompleteLoading] = useLoaderSelector(ShoppingBagCompleteActionTypes.REQUEST);
    const [isError] = useErrorSelector(ShoppingBagCompleteActionTypes.FAILURE);
    const { items } = useSelector(selectShoppingBag);
    const { user } = useSelector(selectAuth);

    const dispatch = useDispatch();

    const { register, handleSubmit, errors } = useForm<ShoppingBagFormValues>({
        mode: 'onTouched',
        defaultValues: {
            name: user?.name || '',
            phone: user?.phone || '',
            address: user?.address || '',
        }
    });

    useEffect(() => {
        dispatchWithPromise<ShoppingBagReadRequestAction>(dispatch, shoppingBagReadActions.request, null);
        dispatchWithPromise<ShoppingBagReadItemsCountAction>(dispatch, shoppingBagReadActions.itemsCount, null);
    }, [dispatch]);

    useEffect(() => setEmpty(items.length === 0), [items])

    const totalPrice = items.reduce(
        (sum, item) => sum + (item.product!.price * item.quantity),
        0
    );

    const onSubmit: SubmitHandler<ShoppingBagFormValues> = (values) => {
        dispatchWithPromise<AuthRequestAction>(dispatch, authActions.request, {
            name: user?.name,
            phone: user?.phone,
            address: values.address,
            city: values.city
        }).then(() => dispatchWithPromise<ShoppingBagCompleteRequestAction>(
            dispatch,
            shoppingBagCompleteActions.request,
            null
        ).then(() => setFinished(true)));
    }

    return (
        <>
            {
                isReadLoading ?
                    <Loader />
                    : <Container type={ContainerEnums.shoppingBag}>
                        <div className="shopping-bag">
                            <Nav />
                            <div className={`shopping-bag__main ${empty ? 'shopping-bag__main--empty' : ''}`}>
                                {
                                    !empty
                                        ? <>
                                            <div className="shopping-bag__box">
                                                <h4 className="shopping-bag__box-heading">Produktet</h4>

                                                <div className="shopping-bag__products-cont">
                                                    <div className="shopping-bag__products">
                                                        {
                                                            items.map(item => (
                                                                <ShoppingBagProduct
                                                                    key={`product-${item.id}`}
                                                                    itemId={item.id}
                                                                    src={item.product!.imgUrl}
                                                                    title={item.product!.title}
                                                                    price={item.product!.price}
                                                                    stock={item.product!.stock}
                                                                    quantity={item.quantity}
                                                                />
                                                            ))
                                                        }
                                                    </div>

                                                    <div className="shopping-bag__total">
                                                        <p className="shopping-bag__shipping-amount">Dërgesa</p>
                                                        <p className="shopping-bag__shipping-amount">0EU</p>
                                                        <p className="shopping-bag__total-amount">Shuma totale</p>
                                                        <p className="shopping-bag__total-amount">
                                                            {totalPrice}EU
                                                            <span>Inc. VAT</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="shopping-bag__box">
                                                <h4 className="shopping-bag__box-heading">Adresa e dërgesës</h4>

                                                <div className="shopping-bag__address">
                                                    <form className="shopping-bag__form">
                                                        <FormGroup
                                                            label="Emri i plotë"
                                                            name="name"
                                                            type="text"
                                                            ref={register({ required: 'Emri është i nevojshëm' })}
                                                            errors={errors}
                                                            disabled
                                                        />
                                                        <FormGroup
                                                            label="Numri i telefonit"
                                                            name="phone"
                                                            type="text"
                                                            ref={register({
                                                                required: 'Numri i telefonit është i nevojshëm',
                                                                minLength: { value: 9, message: 'Numri duhet të jete mes 9 dhe 13 karaktereve' },
                                                                maxLength: { value: 13, message: 'Numri duhet të jete mes 9 dhe 13 karaktereve' },
                                                            })}
                                                            errors={errors}
                                                            disabled
                                                        />
                                                        <FormGroup
                                                            label="Adresa, Numri shtëpiak"
                                                            name="address"
                                                            type="text"
                                                            ref={register({ required: 'Adresa është e nevojshme' })}
                                                            errors={errors}
                                                        />
                                                        <FormGroup
                                                            label="Qyteti"
                                                            name="city"
                                                            type="select"
                                                            options={[
                                                                { value: 'prishtine', label: 'Prishtine' },
                                                                { value: 'gjilan', label: 'Gjilan' },
                                                                { value: 'ferizaj', label: 'Ferizaj' },
                                                                { value: 'prizren', label: 'Prizren' },
                                                                { value: 'gjakove', label: 'Gjakove' },
                                                                { value: 'peje', label: 'Peje' },
                                                                { value: 'fushe kosove', label: 'Fushe Kosove' },
                                                                { value: 'kaçanik', label: 'Kaçanik' },
                                                                { value: 'lipjan', label: 'Lipjan' },
                                                                { value: 'malisheve', label: 'Malisheve' },
                                                                { value: 'mitrovice', label: 'Mitrovice' },
                                                                { value: 'vushtrri', label: 'Vushtrri' },
                                                                { value: 'drenas', label: 'Drenas' },
                                                            ]}
                                                            ref={register({ required: 'Qyteti është i nevojshëm' })}
                                                            errors={errors}
                                                        />

                                                        <Button
                                                            onClick={handleSubmit(onSubmit)}
                                                            type={ButtonTypes.primary}
                                                        >
                                                            {isCompleteLoading ? 'Përpunuar...' : 'Kompleto'}
                                                        </Button>
                                                    </form>
                                                    {isError
                                                        ? <Error size={ErrorSizeTypes.medium} message="Porosia nuk ka mundur të kompletohet" />
                                                        : <p className="shopping-bag__info">
                                                            <img src={TooltipIcon} alt="Tooltip" /> Pagesa bëhet kur dorëzohet produkti/et
                                                        </p>
                                                    }
                                                </div>
                                            </div>

                                            {
                                                finished && <Modal onClose={() => setEmpty(true)} >
                                                    <SuccessPrompt to="/products" label="See other products"><p>Ju falenderojmë për porosinë!</p></SuccessPrompt>
                                                </Modal>
                                            }
                                        </>
                                        :
                                        <div className="shopping-bag__empty">
                                            <p>Shporta eshte boshe :(</p>
                                            <Button to="/products" link="link" type={ButtonTypes.dark}>Shiko produkte tjera</Button>
                                        </div>
                                }
                            </div>
                        </div>
                        <Footer />
                    </Container>
            }
        </>
    )
};

export { ShoppingBag };