import { BaseAction } from '../base/base.types';
import { Barber, Product } from 'resources/types';

/**
 * Landing Init State
 */
export type LandingInitState = {
    barbers: Barber[],
    products: Product[],
    itemsCount: number
}

/**
 * Landing Action Types
 */
export enum LandingActionTypes {
    REQUEST = '@app/landing/REQUEST',
    SUCCESS = '@app/landing/SUCCESS',
    FAILURE = '@app/landing/FAILURE',
    UPDATE_ITEMS_COUNT = '@app/landing/UPDATE_ITEMS_COUNT',
    SET_ITEMS_COUNT = '@app/landing/SET_ITEMS_COUNT',
};

/**
 * Landing Actions
 */
export type LandingRequestAction = BaseAction & {
    type: LandingActionTypes.REQUEST
}
export type LandingSuccessAction = BaseAction & {
    type: LandingActionTypes.SUCCESS,
    payload: { barbers: Barber[], products: Product[], itemsCount: string }
}
export type LandingFailureAction = BaseAction & {
    type: LandingActionTypes.FAILURE,
    payload: {}
}
export type LandingUpdateItemsCountAction = BaseAction & {
    type: LandingActionTypes.UPDATE_ITEMS_COUNT,
    payload: number
}
export type LandingSetItemsCountAction = BaseAction & {
    type: LandingActionTypes.SET_ITEMS_COUNT,
    payload: string
}

/**
 * All Landing Actions
 */
export type LandingActions =
    LandingRequestAction
    | LandingSuccessAction
    | LandingFailureAction
    | LandingUpdateItemsCountAction
    | LandingSetItemsCountAction;