import React, { FC } from 'react';

import { CreditsProps } from './Credits.types';

const Credits: FC<CreditsProps> = ({ mode }) => {
    return <span className={`credits credits--${mode}`}>
        <span className="credits__tooltip">Made by</span>
        <a href="https://www.instagram.com/sailsagency/" target="_blank" rel="noreferrer">Sails</a>
    </span>
};

export { Credits }