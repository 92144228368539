import React from 'react';

import Menspire from 'static/svgs/menspire.svg';
import Toniguy from 'static/svgs/toniguy.svg';
import Sassoon from 'static/svgs/sassoon.svg';

const Certification = () => {
    return <div className="certification">
        <img src={Toniguy} alt="Toni&Guy" />
        <img src={Menspire} alt="Menspire" />
        <img src={Sassoon} alt="Sassoon" />
    </div>
};

export { Certification };