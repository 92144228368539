import React from 'react';
import { Link } from 'react-router-dom';

import { Logo } from 'components/atoms/logo/Logo'
import { Credits } from 'components/atoms/credits/Credits'
import { LogoTypes } from 'enums/Logo.enums';

const Footer = () => {
    return <footer className="footer">
        <div className="footer__group">
            <h4 className="footer__heading">Contact</h4>
            <p className="footer__info">Rruga Robert Doll, 118, Prishtinë</p>
            <p className="footer__info">+383 44 600 126</p>
            <p className="footer__info">trend.grooming@gmail.com</p>
        </div>

        <div className="footer__group">
            <h4 className="footer__heading">Follow Trend</h4>
            <p className="footer__info"><a href="https://www.instagram.com/trendhairdressing/">Instagram</a></p>
            <p className="footer__info"><a href="https://www.facebook.com/trendhairdressing">Facebook</a></p>
            <p className="footer__info"><a href="mailto:trend.grooming@gmail.com">Mail</a></p>
            <Credits mode="display" />
        </div>

        <Link to="/">
            <Logo type={LogoTypes.white} />
        </Link>
    </footer>
};

export { Footer }