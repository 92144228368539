import { actionCreator } from 'utils/action-creator';
import { ShoppingBagInitState, ShoppingBagActionTypes, ShoppingBagActions, ShoppingBagReadAction, ShoppingBagAddAction, ShoppingBagUpdateAction, ShoppingBagDeleteAction, ShoppingBagCompleteAction } from './shopping-bag.types';

/**
 * Redux Actions
 */
export const shoppingBagActions = {
    read: actionCreator<ShoppingBagReadAction>(ShoppingBagActionTypes.READ),
    add: actionCreator<ShoppingBagAddAction>(ShoppingBagActionTypes.ADD),
    update: actionCreator<ShoppingBagUpdateAction>(ShoppingBagActionTypes.UPDATE),
    delete: actionCreator<ShoppingBagDeleteAction>(ShoppingBagActionTypes.DELETE),
    complete: actionCreator<ShoppingBagCompleteAction>(ShoppingBagActionTypes.COMPLETE)
};

/**
 * Init State
 */
export const initState: ShoppingBagInitState = {
    items: []
}

/**
 * Default Reducer
 *
 * @param state
 * @param action
 */
export const shoppingBagReducer = (state = initState, action: ShoppingBagActions) => {
    switch (action.type) {
        case ShoppingBagActionTypes.READ:
            return { ...state, items: action.payload.items };

        case ShoppingBagActionTypes.ADD:
            return { ...state }

        case ShoppingBagActionTypes.UPDATE:
            return { ...state, items: [...state.items.filter(i => i.id !== action.payload.id), action.payload] }

        case ShoppingBagActionTypes.DELETE:
            return { ...state, items: [...state.items.filter(i => i.id !== action.payload.id)] }

        case ShoppingBagActionTypes.COMPLETE:
            return state;

        default:
            return state;
    }
};
