import React, { FC } from 'react';

import { Heading } from 'components/atoms/heading/Heading';

import { HeadingTypes } from 'enums/Heading.enums';

import { NavigableHeaderProps } from './NavigableHeader.types';

import BarbersButtonRight from 'static/svgs/barbers-button-right.svg';
import BarbersButtonLeft from 'static/svgs/barbers-button-left.svg';

const NavigableHeader: FC<NavigableHeaderProps> = ({ extendedClass, onButtonClick }) => {
    return (
        <div className={`navigable-header ${extendedClass}`}>
            <Heading type={HeadingTypes.tertiary}>Berberat</Heading>

            <div className="navigable-header__btn-group">
                <button onClick={e => onButtonClick(e, 'left')} className="navigable-header__button navigable-header__button--inactive">
                    <img src={BarbersButtonLeft} alt="Barbers button left" />
                </button>
                <button onClick={e => onButtonClick(e, 'right')} className="navigable-header__button">
                    <img src={BarbersButtonRight} alt="Barbers button right" />
                </button>
            </div>
        </div>
    )
};

export { NavigableHeader }