import React, { FC } from 'react';

import { ModalProps } from './Modal.types';

const Modal: FC<ModalProps> = ({ children, onClose }) => {
    return (
        <div className="modal">
            <div className="modal__background" onClick={onClose}>&nbsp;</div>
            <div className="modal__content">
                <button className="modal__close" onClick={onClose}>
                    <span className="menu__icon">&nbsp;</span>
                </button>
                {children}
            </div>
        </div>
    )
};

export { Modal };