import React, { FC, useState } from 'react';

import { ProductsProps } from './Products.types';
import { Product } from 'resources/types';
import { HeadingTypes } from 'enums/Heading.enums';
import { ButtonTypes } from 'enums/Button.enums';

import { Button } from 'components/atoms/button/Button';
import { Pagination } from 'components/molecules/pagination/Pagination';
import { Heading } from 'components/atoms/heading/Heading';
import { ProductCard } from 'components/molecules/product-card/ProductCard';
import { ProductModal } from 'components/organisms/product-modal/ProductModal'

const Products: FC<ProductsProps> = ({ items, title, layout, total }) => {
    const [item, setItem] = useState<Product>();
    const limit = 9;

    return (
        <section className={`products products--${layout}`} id="produktet">
            <Heading type={HeadingTypes.tertiary}>{title}</Heading>

            <div className="products__cards">
                {items.map(item => <ProductCard
                    key={item.id}
                    src={item.imgUrl}
                    title={item.title}
                    price={item.price}
                    stock={item.stock}
                    onClick={() => setItem(item)}
                />)}
            </div>

            <div className="products__bottom">
                {
                    layout === 'section'
                        ? <Button link="link" type={ButtonTypes.outlined} to="/products">Shiko te gjitha</Button>
                        : <Pagination pageCount={Math.ceil(total! / limit)} />
                }
            </div>

            { item && <ProductModal item={item} onClose={() => setItem(undefined)} />}
        </section>
    )
}

export { Products };