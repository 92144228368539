import React, { FC } from 'react';

import { InstallMessageProps } from './InstallMessage.types';

import IosShare from 'static/svgs/ios-share.svg';

const InstallMessage: FC<InstallMessageProps> = ({ onClick }) => {
    return (
        <div className="install-message" onClick={onClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56">
                <rect id="Rectangle_257" data-name="Rectangle 257" width="56" height="56" rx="4" fill="#6f6f6f" />
                <g id="Group_336" data-name="Group 336" transform="translate(-3077 -1331)">
                    <path id="Path_218" data-name="Path 218" d="M4854-2565v29.075" transform="translate(-1749 3909.041)" fill="none" stroke="#fff" stroke-width="2" />
                    <path id="Path_219" data-name="Path 219" d="M0,0V29.075" transform="translate(3119.538 1358.582) rotate(90)" fill="none" stroke="#fff" stroke-width="2" />
                </g>
            </svg>

            <p>
                To install this webapp on your device, tap <img className="install-message__share" src={IosShare} alt="Ios share" /> and then <span style={{ fontWeight: 'bold' }}>"Add to Home Screen"</span>
            </p>
        </div>
    )
};

export { InstallMessage };