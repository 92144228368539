import { actionCreator } from 'utils/action-creator';
import { BaseErrorsActionTypes, BaseAction, CatchersInitState } from './base.types';

/**
 * Init State
 */
export const initState: CatchersInitState = {};

/**
 * Default Reducer
 *
 * @param state
 * @param action
 */
export const errorsReducer = (state = initState, { type, payload }: BaseAction) => {
    /*
     * Reset State
     */
    if (type === BaseErrorsActionTypes.RESET) {
        return initState;
    }

    /*
     * Remove Single Action
     */
    if (type === BaseErrorsActionTypes.REMOVE && typeof payload === 'string') {
        const { payload: value, ...removedState } = state;
        return removedState;
    }

    const isSuccess = type.includes('SUCCESS');
    const isRequest = type.includes('REQUEST');
    const isFailure = type.includes('FAILURE');


    if (isFailure) {
        return { ...state, [type]: payload };
    }

    if (isRequest || isSuccess) {
        // If SUCCESS, REQUEST of that same saga emerges, get rid of the error that was previously here
        const { [type.replace(/SUCCESS|REQUEST/, 'FAILURE')]: value, ...restState } = state;

        return restState;
    }

    return state;
}

/**
 * Redux Actions
 */
export const errorsActions = {
    remove: actionCreator(BaseErrorsActionTypes.REMOVE),
    reset: actionCreator(BaseErrorsActionTypes.RESET)
};
