import React, { FC } from 'react';

import { HighlightedWordProps } from './HighlightedWord.types';

const HighlightedWord: FC<HighlightedWordProps> = ({ text }) => {
    return (
        <span className="highlighted-word">{text}</span>
    )
};

export { HighlightedWord };