import { takeLatest, put } from 'redux-saga/effects';

import { axios } from 'utils/axios';
import { actionCreator } from 'utils/action-creator';
import { Barber, Product } from 'resources/types';
import {
    LandingInitState,
    LandingActionTypes,
    LandingRequestAction,
    LandingSuccessAction,
    LandingFailureAction,
    LandingActions,
    LandingUpdateItemsCountAction,
    LandingSetItemsCountAction
} from './landing.types';

/**
 * Redux Actions
 */
export const landingActions = {
    request: actionCreator<LandingRequestAction>(LandingActionTypes.REQUEST),
    success: actionCreator<LandingSuccessAction>(LandingActionTypes.SUCCESS),
    failure: actionCreator<LandingFailureAction>(LandingActionTypes.FAILURE),
    updateItemsCount: actionCreator<LandingUpdateItemsCountAction>(LandingActionTypes.UPDATE_ITEMS_COUNT),
    setItemsCount: actionCreator<LandingSetItemsCountAction>(LandingActionTypes.SET_ITEMS_COUNT)
};

/**
 * Init State
 */
export const initState: LandingInitState = {
    barbers: [{
        id: 0,
        name: '',
        rank: '',
        rating: 0,
        imgUrl: '',
        createdAt: '',
        updatedAt: '',
        services: [{
            id: 0,
            title: '',
            price: 0,
            duration: 0,
            barberId: 0,
            createdAt: '',
            updatedAt: ''
        }]
    }],
    products: [{
        id: 0,
        title: '',
        brand: '',
        description: '',
        price: 0,
        stock: 0,
        imgUrl: '',
        createdAt: '',
        updatedAt: '',
        pinned: false
    }],
    itemsCount: 0
}

/**
 * Default Reducer
 *
 * @param state
 * @param action
 */
export const landingReducer = (state = initState, action: LandingActions) => {
    switch (action.type) {
        case LandingActionTypes.REQUEST:
            return { ...state }

        case LandingActionTypes.SUCCESS:
            return { ...state, barbers: action.payload.barbers, products: action.payload.products, itemsCount: parseInt(action.payload.itemsCount) }

        case LandingActionTypes.UPDATE_ITEMS_COUNT:
            return { ...state, itemsCount: state.itemsCount + action.payload }

        case LandingActionTypes.SET_ITEMS_COUNT:
            return { ...state, itemsCount: parseInt(action.payload) }

        default:
            return state;
    }
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ payload, resolve, reject }: LandingRequestAction) {
        try {
            const res = yield axios.get<{ barbers: Barber[], products: Product[], itemsCount: number }>('/landing');
            yield put(landingActions.success(res.data));
            resolve!(res.data);
        } catch (err) {
            yield put(landingActions.failure(err.response.data.errors));
            reject!(err);
        }
    }
}

/**
 * Saga Watchers
 */
export const landingWatchers = function* () {
    yield takeLatest(LandingActionTypes.REQUEST, sagas.request)
}
