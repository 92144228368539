import React, { FC } from 'react';

import { ErrorProps } from './Error.types';

import ErrorIcon from 'static/svgs/error-icon.svg';

const Error: FC<ErrorProps> = ({ message, size = "small" }) => {
    return <p className={`error error--${size}`}><img src={ErrorIcon} alt="Error" /> {message}</p>
};

export { Error }