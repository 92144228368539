import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { App } from 'App';

// Import configured store
import configureStore from 'store/configure-store';

import { setupAxios } from 'utils/axios';

// Setup store
const initialState = {};
const store = configureStore(initialState)

// Setup axios
setupAxios();

ReactDOM.render(
    <Provider store={store}>
        <Suspense fallback={<div>Loading...</div>}>
            <App />
        </Suspense>
    </Provider>,
    document.getElementById('root')
);
