import { BaseAction } from '../base/base.types';

/**
 * Booking Init State
 */
export type BookingInitState = {
    appointments: string[]
}

/**
 * Booking Action Types
 */
export enum BookingActionTypes {
    APPOINTMENTS = '@app/booking/APPOINTMENTS',
    APPOINTMENTS_RESET = '@app/booking/APPOINTMENTS_RESET'
};

export enum BookingAppointmentsActionTypes {
    REQUEST = '@app/booking/appointments/REQUEST',
    SUCCESS = '@app/booking/appointments/SUCCESS',
    FAILURE = '@app/booking/appointments/FAILURE',
}

export enum BookingAddActionTypes {
    REQUEST = '@app/booking/add/REQUEST',
    SUCCESS = '@app/booking/add/SUCCESS',
    FAILURE = '@app/booking/add/FAILURE',
}

/**
 * Booking Actions
 */
export type BookingAppointmentsAction = BaseAction & {
    type: BookingActionTypes.APPOINTMENTS,
    payload: { appointments: string[] }
}
export type BookingAppointmentsResetAction = BaseAction & {
    type: BookingActionTypes.APPOINTMENTS_RESET,
    payload: null
}


export type BookingAppointmentsRequestAction = BaseAction & {
    type: BookingAppointmentsActionTypes.REQUEST,
    payload: { date: string | Date, barberId: number }
}
export type BookingAppointmentsSuccessAction = BaseAction & {
    type: BookingAppointmentsActionTypes.SUCCESS,
    payload: null
}
export type BookingAppointmentsFailureAction = BaseAction & {
    type: BookingAppointmentsActionTypes.FAILURE,
    payload: {}
}


export type BookingAddRequestAction = BaseAction & {
    type: BookingAddActionTypes.REQUEST,
    payload: {
        barberId: number,
        serviceIds: number[],
        date: string,
        time: string
    }
}
export type BookingAddSuccessAction = BaseAction & {
    type: BookingAddActionTypes.SUCCESS,
    payload: null
}
export type BookingAddFailureAction = BaseAction & {
    type: BookingAddActionTypes.FAILURE,
    payload: {}
}

/**
 * All Booking Actions
 */
export type BookingActions = BookingAppointmentsAction | BookingAppointmentsResetAction;