import React, { FC } from 'react';

import { ContainerProps } from './Container.types';

const Container: FC<ContainerProps> = ({ children, type }) => {
    return <div className={`container container--${type}`}>
        {children}
    </div>
};

export { Container };