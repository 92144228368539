import 'lazysizes';
import 'styles/main.scss';
import React, { useEffect, useState } from 'react';
import { history } from 'utils/history';
import { Router, Route, Switch } from 'react-router-dom';

import { InstallMessage } from 'components/atoms/install-message/InstallMessage';
import { Landing } from 'pages/landing/Landing';
import { AllProducts } from 'pages/all-products/AllProducts';
import { ShoppingBag } from 'pages/shopping-bag/ShoppingBag';

const App = () => {
    const [showInstallMessage, setShowInstallMessage] = useState<boolean>(false);

    useEffect(() => {
        setTimeout(() => {
            const popupShownOnce = window.localStorage.getItem('install:popup:shown');
            const isIos = () => {
                const userAgent = window.navigator.userAgent.toLowerCase();
                return /iphone|ipod/.test(userAgent);
            }

            const isInStandaloneMode = () => ('standalone' in window.navigator) && ((window.navigator as any).standalone);

            if (isIos() && !isInStandaloneMode() && popupShownOnce !== '1') {
                setShowInstallMessage(true)
                setTimeout(() => setShowInstallMessage(false), 12000)
                window.localStorage.setItem('install:popup:shown', '1');
            }
        }, 10000)
    }, []);

    const onClick = () => {
        setShowInstallMessage(false);
        window.localStorage.setItem('install:popup:shown', '1');
    }

    return (
        <Router history={history}>
            {showInstallMessage && <InstallMessage onClick={onClick} />}
            <>
                <Switch>
                    <Route exact={true} path="/shopping-bag" component={ShoppingBag} />
                    <Route exact={true} path="/products" component={AllProducts} />
                    <Route exact={true} path="/" component={Landing} />
                </Switch>
            </>
        </Router>
    )
};


export { App };
