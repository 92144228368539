import React from 'react';

import { Logo } from 'components/atoms/logo/Logo';
import { WithAnimation } from 'components/hoc/with-animation/WithAnimation';

const Loader = () => {
    return (
        <div className="loader">
            <WithAnimation>
                <Logo />
            </WithAnimation>
        </div>
    );
};

export { Loader };
