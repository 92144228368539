import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

// Import reducers
import { rootReducer } from './reducers';

// Import sagas
import sagas from 'sagas';
import { AuthInitRequestAction } from 'sagas/auth/auth.types';
import { authInitActions } from 'sagas/auth/auth-init';


// Create saga middleware
const sagaMiddleware = createSagaMiddleware();

// createStore
export default function configureStore(initialState = {}) {
    const middlewares = [sagaMiddleware];

    const enhancers = [applyMiddleware(...middlewares)];

    // If Redux DevTools Extension is installed use it, otherwise use Redux compose
    /* eslint-disable no-underscore-dangle, indent */
    const composeEnhancers =
        process.env.NODE_ENV !== 'production' &&
            typeof window === 'object' &&
            // @ts-ignore
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            // @ts-ignore
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                shouldHotReload: false
            })
            : compose;

    const store = createStore(rootReducer, initialState, composeEnhancers(...enhancers));

    // Initiate sagas
    sagaMiddleware.run(sagas);

    // Run auth init
    store.dispatch<AuthInitRequestAction>(authInitActions.request({}));

    return store;
}