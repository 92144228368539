import { takeLatest, put } from 'redux-saga/effects';
import decode from 'jwt-decode';

import { axios } from 'utils/axios';
import { actionCreator } from 'utils/action-creator';
import { AuthActions, AuthActionTypes, AuthFailureAction, AuthInitState, AuthRequestAction, AuthRegisterAction, AuthSuccessAction } from './auth.types';
import { User } from 'resources/types';

/**
 * Redux Actions
 */
export const authActions = {
    request: actionCreator<AuthRequestAction>(AuthActionTypes.REQUEST),
    register: actionCreator<AuthRegisterAction>(AuthActionTypes.REGISTER),
    success: actionCreator<AuthSuccessAction>(AuthActionTypes.SUCCESS),
    failure: actionCreator<AuthFailureAction>(AuthActionTypes.FAILURE)
};

/**
 * Init State
 */
export const initState: AuthInitState = {
    isAuthenticated: false,
    user: {
        id: 0,
        name: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        type: 0
    }
}

/**
 * Default Reducer
 *
 * @param state
 * @param action
 */
export const authReducer = (state = initState, action: AuthActions) => {
    switch (action.type) {
        case AuthActionTypes.REQUEST:
            return { ...state }

        case AuthActionTypes.SUCCESS:
            return { ...state, isAuthenticated: true, user: action.payload.user }

        default:
            return state;
    }
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ payload, resolve, reject }: AuthRequestAction) {
        try {
            const res = yield axios.post<{ user: User, token: string }>('/auth', payload);
            yield put(authActions.register({ token: res.data.token }));
            resolve!(res.data);
        } catch (err) {
            yield put(authActions.failure(err.response.data.errors));
            reject!(err);
        }
    },
    *register({ payload }: AuthRegisterAction) {
        const decoded: User & { iat?: string } | null = decode(payload.token);
        if (decoded) {
            delete decoded.iat;
            yield put(authActions.success({ user: decoded }));
            yield localStorage.setItem('auth:token', payload.token);
        }
    }
}

/**
 * Saga Watchers
 */
export const authWatchers = function* () {
    yield takeLatest(AuthActionTypes.REQUEST, sagas.request)
    yield takeLatest(AuthActionTypes.REGISTER, sagas.register)
}
