import { BookingActionTypes, BookingAddActionTypes } from 'sagas/booking/booking.types';
import { ProductsActionTypes } from 'sagas/products/products.types';
import {
    ShoppingBagReadActionTypes,
    ShoppingBagAddActionTypes,
    ShoppingBagUpdateActionTypes,
    ShoppingBagDeleteActionTypes,
    ShoppingBagCompleteActionTypes,
} from 'sagas/shopping-bag/shopping-bag.types';
import { AuthActionTypes } from '../auth/auth.types';
import { LandingActionTypes } from '../landing/landing.types';

/**
 * Catchers Init State
 */
export type CatchersInitState = {
    [key: string]: any
}

/**
 * Base Action Type
 */
export type BaseAction = {
    type: string, payload: {} | string | null, resolve?: (value: unknown) => void, reject?: (reason?: any) => void
}

/**
 * Base Action Creator
 */
export type BaseActionCreator<T extends BaseAction> = (payload: T['payload'], resolve?: T['resolve'], reject?: T['reject']) => T;

/**
 * Base Errors Action Types
 */
export enum BaseErrorsActionTypes {
    REMOVE = '@app/errors/REMOVE',
    RESET = '@app/errors/RESET'
};

/**
 * Base Loaders Action Types
 */
export enum BaseLoadersActionTypes {
    REMOVE = '@app/loaders/REMOVE',
    RESET = '@app/loaders/RESET'
};

/**
 * All app actions
 */
export type AllActionTypes =
    AuthActionTypes
    | LandingActionTypes
    | BookingActionTypes
    | BookingAddActionTypes
    | ProductsActionTypes
    | ShoppingBagReadActionTypes
    | ShoppingBagAddActionTypes
    | ShoppingBagUpdateActionTypes
    | ShoppingBagDeleteActionTypes
    | ShoppingBagDeleteActionTypes
    | ShoppingBagCompleteActionTypes;