import React from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from "react-scroll";

import { history } from 'utils/history';

import { SocialMediaPlug } from 'components/atoms/social-media-plug/SocialMediaPlug';

const sections = ['rreth ne', 'berberat', 'produktet'];

const Menu = () => {
    const generateLinks = (): React.ReactNode[] => {
        if (history.location.pathname === '/') {
            return sections.map(section => (
                <li key={section} className="menu__item">
                    <ScrollLink
                        to={section}
                        smooth={true}
                        duration={500} className="menu__link"
                    >
                        {section.charAt(0).toUpperCase() + section.slice(1)}
                    </ScrollLink>
                </li>
            ));
        } else {
            return sections.map(section => (
                <li key={section} className="menu__item">
                    <Link to={`/#${section}`} className="menu__link">
                        {section.charAt(0).toUpperCase() + section.slice(1)}
                    </Link>
                </li >
            ));
        }
    }

    return (
        <div className="menu">
            <input type="checkbox" className="menu__checkbox" id="menu-toggle" />

            <label htmlFor="menu-toggle" className="menu__button">
                <span className="menu__icon">&nbsp;</span>
            </label>

            <div className="menu__background">
                <nav className="menu__nav">
                    <ul className="menu__list">
                        {generateLinks()}

                        <li className="menu__item">
                            <Link to="/shopping-bag" className="menu__link">Shporta</Link>
                        </li>
                    </ul>

                    <SocialMediaPlug mode="light" font="display" />
                </nav>
            </div>

        </div>
    );
};

export { Menu };