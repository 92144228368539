import React, { FC, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import { ButtonProps } from './Button.types';

const Button: FC<ButtonProps> = ({ type, onClick, children, link, to, animate, disabled = false }) => {
    const onButtonClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        onClick && onClick!();
    }

    if (link === 'link')
        return <Link className={`btn btn--link btn--${type}`} to={to!}>{children}</Link>
    else if (link === 'scroll')
        return <ScrollLink to={to!} className={`btn btn--link btn--${type} ${animate ? 'btn--animated' : ''}`} smooth={true}>{children}</ScrollLink>

    return <button className={`btn btn--${type}`} onClick={onButtonClick} disabled={disabled}>{children}</button>
};

export { Button };