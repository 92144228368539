import axios from 'axios';
import { takeLatest, put } from 'redux-saga/effects';
import { ShoppingBagItem } from 'resources/types';
import { landingActions } from 'sagas/landing/landing';
import { actionCreator } from 'utils/action-creator';
import { shoppingBagActions } from './shopping-bag';
import {
    ShoppingBagReadActionTypes,
    ShoppingBagReadRequestAction,
    ShoppingBagReadSuccessAction,
    ShoppingBagReadFailureAction,
    ShoppingBagReadItemsCountAction
} from './shopping-bag.types';

/**
 * Redux Actions
 */
export const shoppingBagReadActions = {
    request: actionCreator<ShoppingBagReadRequestAction>(ShoppingBagReadActionTypes.REQUEST),
    success: actionCreator<ShoppingBagReadSuccessAction>(ShoppingBagReadActionTypes.SUCCESS),
    failure: actionCreator<ShoppingBagReadFailureAction>(ShoppingBagReadActionTypes.FAILURE),
    itemsCount: actionCreator<ShoppingBagReadItemsCountAction>(ShoppingBagReadActionTypes.ITEMS_COUNT)
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ payload, resolve, reject }: ShoppingBagReadRequestAction) {
        try {
            const res = yield axios.get<ShoppingBagItem[]>(`/shopping-bag`);

            yield put(shoppingBagActions.read({ items: res.data }));
            yield put(shoppingBagReadActions.success(null));

            resolve!(res.data);
        } catch (err) {
            yield put(shoppingBagReadActions.failure(err.response.data.errors));
            reject!(err);
        }
    },
    *itemsCount({ payload, resolve, reject }: ShoppingBagReadItemsCountAction) {
        try {
            const res = yield axios.get<{ itemsCount: number }>('/shopping-bag/items-count');

            yield put(landingActions.setItemsCount(res.data.itemsCount));

            resolve!(res.data);
        } catch (err) {
            yield put(shoppingBagReadActions.failure(err.response.data.errors));
            reject!(err);
        }
    }
}

/**
 * Saga Watchers
 */
export const shoppingBagReadWatchers = function* () {
    yield takeLatest(ShoppingBagReadActionTypes.REQUEST, sagas.request);
    yield takeLatest(ShoppingBagReadActionTypes.ITEMS_COUNT, sagas.itemsCount);
}
