import React, { FC, forwardRef } from 'react';

import { SelectProps } from './Select.types';

const Select: FC<SelectProps> = forwardRef(({ name, options, selectedOption, onChange, disabled }, ref) => {
    const elemOptions = options?.map(({ value, label }, index) => <option key={`${value}-${index}`} value={value}>{label}</option>)

    return (
        <div className="select">
            <select name={name} ref={ref as null} className="select__input" defaultValue={selectedOption} onChange={onChange} disabled={disabled}>
                {elemOptions}
            </select>
        </div>
    )
});

export { Select };