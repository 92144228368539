import { useSelector, useDispatch } from 'react-redux';
import { selectLoaders } from 'sagas/selectors';
import { loadersActions } from 'sagas/base/loaders';
import { AllActionTypes } from 'sagas/base/base.types';

export const useLoaderSelector = (actionType: AllActionTypes) => {
    const loaders = useSelector(selectLoaders);
    const dispatch = useDispatch();

    // Check that we have an actionType
    if (actionType in loaders) {
        return [
            loaders[actionType],
            () => dispatch(loadersActions.remove(actionType)),
            () => dispatch(loadersActions.reset({}))
        ];
    }

    // Return empty
    return [null, () => { }, () => { }];
};

