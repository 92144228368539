import axios from 'axios';
import { takeLatest, put } from 'redux-saga/effects';
import { ShoppingBagItem } from 'resources/types';
import { actionCreator } from 'utils/action-creator';
import { shoppingBagActions } from './shopping-bag';
import { ShoppingBagUpdateActionTypes, ShoppingBagUpdateRequestAction, ShoppingBagUpdateSuccessAction, ShoppingBagUpdateFailureAction } from './shopping-bag.types';

/**
 * Redux Actions
 */
export const shoppingBagUpdateActions = {
    request: actionCreator<ShoppingBagUpdateRequestAction>(ShoppingBagUpdateActionTypes.REQUEST),
    success: actionCreator<ShoppingBagUpdateSuccessAction>(ShoppingBagUpdateActionTypes.SUCCESS),
    failure: actionCreator<ShoppingBagUpdateFailureAction>(ShoppingBagUpdateActionTypes.FAILURE)
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ payload, resolve, reject }: ShoppingBagUpdateRequestAction) {
        try {
            const res = yield axios.patch<ShoppingBagItem>(`/shopping-bag/${payload.itemId}`, { quantity: payload.quantity });

            yield put(shoppingBagActions.update(res.data));
            yield put(shoppingBagUpdateActions.success(null));

            resolve!(res.data.quantity);
        } catch (err) {
            yield put(shoppingBagUpdateActions.failure(err.response.data.errors));
            reject!(err);
        }
    }
}

/**
 * Saga Watchers
 */
export const shoppingBagUpdateWatchers = function* () {
    yield takeLatest(ShoppingBagUpdateActionTypes.REQUEST, sagas.request)
}
