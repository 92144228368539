import React, { useState, useEffect, FC } from 'react';
import { Link } from 'react-router-dom';
import queryString, { ParsedQuery } from 'query-string';
import { history } from 'utils/history';

import { PaginationProps } from './Pagination.types';

import PaginationLabelBack from 'static/svgs/pagination-label-back.svg';
import PaginationLabelNext from 'static/svgs/pagination-label-next.svg';

const Pagination: FC<PaginationProps> = ({ pageCount }) => {
    const [activePage, setActivePage] = useState<number>(1);
    const params: ParsedQuery<string> = queryString.parse(history.location.search);

    const renderPageItems = () => {
        const items = []
        for (let i = 1; i <= pageCount; i += 1) {
            items.push(
                <li key={`page-${i}`} className={`pagination__page ${activePage === i ? 'pagination__page--active' : ''}`}>
                    <Link className="pagination__link" to={`?page=${i}`}>{i}</Link>
                </li>
            )
        }
        return items;
    }

    useEffect(() => {
        setActivePage(parseInt(params.page as string) || 1);
    }, [params]);

    return (
        <ul className="pagination">
            <li className={`pagination__icon ${activePage === 1 ? 'pagination__icon--inactive' : ''}`}>
                <Link to={activePage !== 1 ? `?page=${activePage - 1}` : `?page=${activePage}`}>
                    <img src={PaginationLabelBack} alt="Pagination back" />
                </Link>
            </li>

            {renderPageItems()}

            <li className={`pagination__icon ${activePage === pageCount ? 'pagination__icon--inactive' : ''}`}>
                <Link to={activePage !== pageCount ? `?page=${activePage + 1}` : `?page=${activePage}`}>
                    <img src={PaginationLabelNext} alt="Pagination next" />
                </Link>
            </li>
        </ul>
    )
};

export { Pagination }