import axios from 'axios';
import { takeLatest, put } from 'redux-saga/effects';
import { actionCreator } from 'utils/action-creator';
import { shoppingBagActions } from './shopping-bag';
import { ShoppingBagDeleteActionTypes, ShoppingBagDeleteRequestAction, ShoppingBagDeleteSuccessAction, ShoppingBagDeleteFailureAction } from './shopping-bag.types';

/**
 * Redux Actions
 */
export const shoppingBagDeleteActions = {
    request: actionCreator<ShoppingBagDeleteRequestAction>(ShoppingBagDeleteActionTypes.REQUEST),
    success: actionCreator<ShoppingBagDeleteSuccessAction>(ShoppingBagDeleteActionTypes.SUCCESS),
    failure: actionCreator<ShoppingBagDeleteFailureAction>(ShoppingBagDeleteActionTypes.FAILURE)
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ payload, resolve, reject }: ShoppingBagDeleteRequestAction) {
        try {
            const res = yield axios.delete<{ result: number }>(`/shopping-bag/${payload.itemId}`);

            yield put(shoppingBagActions.delete({ id: parseInt(res.data.result) }));
            yield put(shoppingBagDeleteActions.success(null));

            resolve!(res.data);
        } catch (err) {
            yield put(shoppingBagDeleteActions.failure(err.response.data.errors));
            reject!(err);
        }
    }
}

/**
 * Saga Watchers
 */
export const shoppingBagDeleteWatchers = function* () {
    yield takeLatest(ShoppingBagDeleteActionTypes.REQUEST, sagas.request)
}
