import axios from 'axios';
import { takeLatest, put } from 'redux-saga/effects';
import { actionCreator } from 'utils/action-creator';
import { bookingActions } from './booking';
import { BookingAppointmentsActionTypes, BookingAppointmentsRequestAction, BookingAppointmentsSuccessAction, BookingAppointmentsFailureAction } from './booking.types';

/**
 * Redux Actions
 */
export const bookingAppointmentsActions = {
    request: actionCreator<BookingAppointmentsRequestAction>(BookingAppointmentsActionTypes.REQUEST),
    success: actionCreator<BookingAppointmentsSuccessAction>(BookingAppointmentsActionTypes.SUCCESS),
    failure: actionCreator<BookingAppointmentsFailureAction>(BookingAppointmentsActionTypes.FAILURE)
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ payload, resolve, reject }: BookingAppointmentsRequestAction) {
        try {
            const res = yield axios.get<{ availableAppointments: string[] }>(`/bookings/available-appointments?date=${payload.date}&barberId=${payload.barberId}`);

            yield put(bookingActions.appointments({ appointments: res.data.availableAppointments }));
            yield put(bookingAppointmentsActions.success(null));

            resolve!(res.data);
        } catch (err) {
            yield put(bookingAppointmentsActions.failure(err.response.data.errors));
            reject!(err);
        }
    }
}

/**
 * Saga Watchers
 */
export const bookingAppointmentsWatchers = function* () {
    yield takeLatest(BookingAppointmentsActionTypes.REQUEST, sagas.request)
}
