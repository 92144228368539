import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectLanding } from 'sagas/selectors';

import { Logo } from 'components/atoms/logo/Logo';
import { Menu } from 'components/molecules/menu/Menu';

import ShoppingBagIcon from 'static/svgs/shopping-bag-icon.svg';

const Nav = () => {
    const { itemsCount } = useSelector(selectLanding);

    return (
        <div className="nav">
            <Link to="/" className="nav__logo">
                <Logo />
            </Link>

            <div className="nav__icons">
                <Link to="/shopping-bag" className="nav__shopping-bag">
                    <div className="nav__shopping-bag-group">
                        <img className="nav__shopping-bag-img" src={ShoppingBagIcon} alt="Shopping bag icon" />
                        <span className="nav__shopping-bag-notification">{itemsCount}</span>
                    </div>
                </Link>

                <Menu />
            </div>
        </div>
    )
};

export { Nav };