import React, { FC } from 'react';
import { WithAnimationProps } from './WithAnimation.types';

const WithAnimation: FC<WithAnimationProps> = ({ children }) => {
    return (
        <div className="fade-in-out">
            {children}
        </div>
    )
};

export { WithAnimation };