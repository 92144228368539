import { actionCreator } from 'utils/action-creator';
import { BookingInitState, BookingActionTypes, BookingAppointmentsAction, BookingAppointmentsResetAction, BookingActions } from './booking.types';

/**
 * Redux Actions
 */
export const bookingActions = {
    appointments: actionCreator<BookingAppointmentsAction>(BookingActionTypes.APPOINTMENTS),
    appointmentsReset: actionCreator<BookingAppointmentsResetAction>(BookingActionTypes.APPOINTMENTS_RESET),
};

/**
 * Init State
 */
export const initState: BookingInitState = {
    appointments: []
}

/**
 * Default Reducer
 *
 * @param state
 * @param action
 */
export const bookingReducer = (state = initState, action: BookingActions) => {
    switch (action.type) {
        case BookingActionTypes.APPOINTMENTS:
            return { ...state, appointments: action.payload.appointments };

        case BookingActionTypes.APPOINTMENTS_RESET:
            return { ...state, appointments: [] }

        default:
            return state;
    }
};
