import React, { FC } from 'react';

import { ImageProps } from './Image.types';

const Image: FC<ImageProps> = ({ src, alt, className }) => {
    return <img
        className={`lazyload ${className || ''}`}
        data-src={src}
        src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3 2'%3E%3C/svg%3E"
        alt={alt}
    />
};

export { Image };
