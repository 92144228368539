import { BaseAction } from '../base/base.types';
import { ShoppingBagItem } from 'resources/types';

/**
 * Booking Init State
 */
export type ShoppingBagInitState = {
    items: ShoppingBagItem[]
}

/**
 * Booking Action Types
 */
export enum ShoppingBagActionTypes {
    READ = '@app/shopping-bag/READ',
    ADD = '@app/shopping-bag/ADD',
    UPDATE = '@app/shopping-bag/UPDATE',
    DELETE = '@app/shopping-bag/DELETE',
    COMPLETE = '@app/shopping-bag/COMPLETE',
};
export enum ShoppingBagReadActionTypes {
    REQUEST = '@app/shopping-bag/read/REQUEST',
    SUCCESS = '@app/shopping-bag/read/SUCCESS',
    FAILURE = '@app/shopping-bag/read/FAILURE',
    ITEMS_COUNT = '@app/shopping-bag/read/ITEMS_COUNT',
}
export enum ShoppingBagAddActionTypes {
    REQUEST = '@app/shopping-bag/add/REQUEST',
    SUCCESS = '@app/shopping-bag/add/SUCCESS',
    FAILURE = '@app/shopping-bag/add/FAILURE',
}
export enum ShoppingBagUpdateActionTypes {
    REQUEST = '@app/shopping-bag/update/REQUEST',
    SUCCESS = '@app/shopping-bag/update/SUCCESS',
    FAILURE = '@app/shopping-bag/update/FAILURE',
}
export enum ShoppingBagDeleteActionTypes {
    REQUEST = '@app/shopping-bag/delete/REQUEST',
    SUCCESS = '@app/shopping-bag/delete/SUCCESS',
    FAILURE = '@app/shopping-bag/delete/FAILURE',
}
export enum ShoppingBagCompleteActionTypes {
    REQUEST = '@app/shopping-bag/complete/REQUEST',
    SUCCESS = '@app/shopping-bag/complete/SUCCESS',
    FAILURE = '@app/shopping-bag/complete/FAILURE',
}

/**
 * Booking Actions
 */
export type ShoppingBagReadAction = BaseAction & {
    type: ShoppingBagActionTypes.READ,
    payload: { items: ShoppingBagItem[] }
}
export type ShoppingBagAddAction = BaseAction & {
    type: ShoppingBagActionTypes.ADD,
    payload: null
}
export type ShoppingBagUpdateAction = BaseAction & {
    type: ShoppingBagActionTypes.UPDATE,
    payload: ShoppingBagItem
}
export type ShoppingBagDeleteAction = BaseAction & {
    type: ShoppingBagActionTypes.DELETE,
    payload: { id: number }
}
export type ShoppingBagCompleteAction = BaseAction & {
    type: ShoppingBagActionTypes.COMPLETE,
    payload: null
}


export type ShoppingBagReadRequestAction = BaseAction & {
    type: ShoppingBagReadActionTypes.REQUEST,
    payload: null
}
export type ShoppingBagReadSuccessAction = BaseAction & {
    type: ShoppingBagReadActionTypes.SUCCESS,
    payload: null
}
export type ShoppingBagReadFailureAction = BaseAction & {
    type: ShoppingBagReadActionTypes.FAILURE,
    payload: {}
}
export type ShoppingBagReadItemsCountAction = BaseAction & {
    type: ShoppingBagReadActionTypes.ITEMS_COUNT,
    payload: null
}


export type ShoppingBagAddRequestAction = BaseAction & {
    type: ShoppingBagAddActionTypes.REQUEST,
    payload: { productId: number, quantity: number }
}
export type ShoppingBagAddSuccessAction = BaseAction & {
    type: ShoppingBagAddActionTypes.SUCCESS,
    payload: null
}
export type ShoppingBagAddFailureAction = BaseAction & {
    type: ShoppingBagAddActionTypes.FAILURE,
    payload: {}
}


export type ShoppingBagUpdateRequestAction = BaseAction & {
    type: ShoppingBagUpdateActionTypes.REQUEST,
    payload: { itemId: number, quantity: number }
}
export type ShoppingBagUpdateSuccessAction = BaseAction & {
    type: ShoppingBagUpdateActionTypes.SUCCESS,
    payload: null
}
export type ShoppingBagUpdateFailureAction = BaseAction & {
    type: ShoppingBagUpdateActionTypes.FAILURE,
    payload: {}
}


export type ShoppingBagDeleteRequestAction = BaseAction & {
    type: ShoppingBagDeleteActionTypes.REQUEST,
    payload: { itemId: number }
}
export type ShoppingBagDeleteSuccessAction = BaseAction & {
    type: ShoppingBagDeleteActionTypes.SUCCESS,
    payload: null
}
export type ShoppingBagDeleteFailureAction = BaseAction & {
    type: ShoppingBagDeleteActionTypes.FAILURE,
    payload: {}
}


export type ShoppingBagCompleteRequestAction = BaseAction & {
    type: ShoppingBagCompleteActionTypes.REQUEST,
    payload: null
}
export type ShoppingBagCompleteSuccessAction = BaseAction & {
    type: ShoppingBagCompleteActionTypes.SUCCESS,
    payload: null
}
export type ShoppingBagCompleteFailureAction = BaseAction & {
    type: ShoppingBagCompleteActionTypes.FAILURE,
    payload: {}
}

/**
 * All Booking Actions
 */
export type ShoppingBagActions =
    ShoppingBagReadAction
    | ShoppingBagAddAction
    | ShoppingBagUpdateAction
    | ShoppingBagDeleteAction
    | ShoppingBagCompleteAction;