import React, { FC } from 'react';

import { SuccessPromptProps } from './SuccessPrompt.types';
import { ButtonTypes } from 'enums/Button.enums';
import { LogoTypes } from 'enums/Logo.enums';

import { Button } from 'components/atoms/button/Button';
import { Logo } from 'components/atoms/logo/Logo';

const SuccessPrompt: FC<SuccessPromptProps> = ({ to, label, children }) => {
    return (
        <div className="success-prompt">
            <Logo type={LogoTypes.white} />
            {children}
            <Button link="link" type={ButtonTypes.outlinedLight} to={to}>{label}</Button>
        </div>
    )
};

export { SuccessPrompt };