import React, { FC } from 'react';

import { Credits } from 'components/atoms/credits/Credits';
import { SocialMediaPlugProps } from './SocialMediaPlug.types';

const SocialMediaPlug: FC<SocialMediaPlugProps> = ({ mode, font = 'primary' }) => {
    return <div className={`social-media-plug social-media-plug--${mode} social-media-plug--${font}`}>
        <a href="https://www.instagram.com/trendhairdressing/" target="_blank" rel="noreferrer">Instagram</a>
        <a href="https://www.facebook.com/trendhairdressing" target="_blank" rel="noreferrer">Facebook</a>
        <Credits mode={mode} />
    </div>
};

export { SocialMediaPlug };
