import moment from 'moment-timezone';

export const validateDate = (date: string) => {
    const currentDate = new Date(moment().tz('Europe/Belgrade').format());
    const startDate = moment(date as string).tz('Europe/Belgrade');
    const endDate = moment(startDate).tz('Europe/Belgrade');
    const isSaturday = startDate.day() === 6;

    // Set hours to Trend working hours
    startDate.set('hour', 10);
    endDate.set('hour', !isSaturday ? 20 : 18);

    if (currentDate >= new Date(endDate.format())) { // Can only make appointments in the future and workdays + saturday
        return 'Terminet mund te behen vetem nga sot e tutje';
    } else if (startDate.day() === 0) {
        return 'Nuk punojme te Dieleve :(';
    }
    return true;
}