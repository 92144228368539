import axios from 'axios';
import { takeLatest, put } from 'redux-saga/effects';
import { actionCreator } from 'utils/action-creator';
import { shoppingBagActions } from './shopping-bag';
import { ShoppingBagAddActionTypes, ShoppingBagAddRequestAction, ShoppingBagAddSuccessAction, ShoppingBagAddFailureAction } from './shopping-bag.types';
import { landingActions } from '../landing/landing';

/**
 * Redux Actions
 */
export const shoppingBagAddActions = {
    request: actionCreator<ShoppingBagAddRequestAction>(ShoppingBagAddActionTypes.REQUEST),
    success: actionCreator<ShoppingBagAddSuccessAction>(ShoppingBagAddActionTypes.SUCCESS),
    failure: actionCreator<ShoppingBagAddFailureAction>(ShoppingBagAddActionTypes.FAILURE)
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ payload, resolve, reject }: ShoppingBagAddRequestAction) {
        try {
            const res = yield axios.post<{ success: true }>(`/shopping-bag`, payload);

            yield put(shoppingBagActions.add(null));
            yield put(shoppingBagAddActions.success(null));

            yield put(landingActions.updateItemsCount(1));

            resolve!(res.data);
        } catch (err) {
            yield put(shoppingBagAddActions.failure(err.response.data.errors));
            reject!(err);
        }
    }
}

/**
 * Saga Watchers
 */
export const shoppingBagAddWatchers = function* () {
    yield takeLatest(ShoppingBagAddActionTypes.REQUEST, sagas.request)
}
