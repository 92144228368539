import React, { FC } from 'react';

import { HeadingProps } from './Heading.types'
import { HeadingTypes } from 'enums/Heading.enums';

const Heading: FC<HeadingProps> = ({ type, children }) => {
    switch (type) {
        case HeadingTypes.primary:
            return <h1 className={`heading heading--${type}`}>{children}</h1>

        case HeadingTypes.secondary:
            return <h2 className={`heading heading--${type}`}>{children}</h2>

        case HeadingTypes.tertiary:
            return <h3 className={`heading heading--${type}`}>{children}</h3>

        default:
            return <h1 className={`heading heading--${type}`}>{children}</h1>
    }
};

export { Heading };