import { takeLatest, put } from 'redux-saga/effects';
import { actionCreator } from 'utils/action-creator';
import { authActions } from './auth';
import { AuthInitActionTypes, AuthInitFailureAction, AuthInitRequestAction, AuthInitSuccessAction } from './auth.types';

/**
 * Redux Actions
 */
export const authInitActions = {
    request: actionCreator<AuthInitRequestAction>(AuthInitActionTypes.REQUEST),
    success: actionCreator<AuthInitSuccessAction>(AuthInitActionTypes.SUCCESS),
    failure: actionCreator<AuthInitFailureAction>(AuthInitActionTypes.FAILURE)
};

/**
 * Saga Functions
 */
export const sagas = {
    *request() {
        try {
            const token = yield localStorage.getItem('auth:token');

            if (token) {
                yield put(authActions.register({ token }));
            }
        } catch (err) {
            yield put(authInitActions.failure(err.response.data.errors));
        }
    }
}

/**
 * Saga Watchers
 */
export const authInitWatchers = function* () {
    yield takeLatest(AuthInitActionTypes.REQUEST, sagas.request)
}
