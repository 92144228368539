import React from 'react';
import { Link as ScrollLink } from "react-scroll";

import { HeadingTypes } from 'enums/Heading.enums';
import { ButtonTypes } from 'enums/Button.enums';

import { Button } from 'components/atoms/button/Button';
import { Nav } from 'components/organisms/nav/Nav';
import { SocialMediaPlug } from 'components/atoms/social-media-plug/SocialMediaPlug'
import { Heading } from 'components/atoms/heading/Heading';

import HeaderLine from 'static/svgs/header-line.svg';

const Header = () => {
    return (
        <header className="header">
            <Nav />

            <div className="header__details">
                <Heading type={HeadingTypes.primary}>
                    'Ku tradita takon <span className="header__select-word">trendin</span>
                </Heading>

                <Button to="barbers" link="scroll" type={ButtonTypes.dark} animate={true}>Bëj termin</Button>
            </div>

            <div className="header__end">
                <SocialMediaPlug mode="dark" />
                <ScrollLink
                    to="barbers"
                    smooth={true}
                    duration={500}
                    className="menu__link"
                >
                    <img src={HeaderLine} alt="Header graphic" />
                </ScrollLink>
            </div>
        </header>
    )
};

export { Header };