import { all } from 'redux-saga/effects';

// Watchers
import { authWatchers } from 'sagas/auth/auth';
import { authInitWatchers } from 'sagas/auth/auth-init';
import { landingWatchers } from 'sagas/landing/landing';
import { bookingAppointmentsWatchers } from 'sagas/booking/booking-appointments';
import { bookingAddWatchers } from 'sagas/booking/booking-add';
import { productsWatchers } from 'sagas/products/products';
import { shoppingBagReadWatchers } from 'sagas/shopping-bag/shopping-bag-read';
import { shoppingBagAddWatchers } from 'sagas/shopping-bag/shopping-bag-add';
import { shoppingBagUpdateWatchers } from 'sagas/shopping-bag/shopping-bag-update';
import { shoppingBagDeleteWatchers } from 'sagas/shopping-bag/shopping-bag-delete';
import { shoppingBagCompleteWatchers } from 'sagas/shopping-bag/shopping-bag-complete';

export default function* root() {
    yield all([
        authWatchers(),
        authInitWatchers(),
        landingWatchers(),
        bookingAppointmentsWatchers(),
        bookingAddWatchers(),
        productsWatchers(),
        shoppingBagReadWatchers(),
        shoppingBagAddWatchers(),
        shoppingBagUpdateWatchers(),
        shoppingBagDeleteWatchers(),
        shoppingBagCompleteWatchers()
    ])
}
