import React, { useState, useRef, useEffect, FC, useMemo } from 'react';
import gsap from 'gsap';

import { BarbersProps } from './Barbers.types';

import { Barber } from 'resources/types';
import { ButtonTypes } from 'enums/Button.enums';

import { Button } from 'components/atoms/button/Button';
import { NavigableHeader } from 'components/molecules/navigable-header/NavigableHeader';
import { BarberCard } from 'components/molecules/barber-card/BarberCard';
import { BarberModal } from 'components/organisms/barber-modal/BarberModal';

const Barbers: FC<BarbersProps> = ({ barbers }) => {
    const [activeBarber, setActiveBarber] = useState<Barber>();

    const [clicksLeft, setClicksLeft] = useState<number>(0);
    const [translateItems, setTranslateItems] = useState<number>(1);
    const [cards, setCards] = useState<ChildNode[]>([]);
    const [additionalMargin, setAdditionalMargin] = useState<number>(0);
    const [elementWidth, setElementWidth] = useState<number>(0);
    const [overlay, setOverlay] = useState<boolean>(true);
    const cardsRef = useRef<HTMLDivElement>(null);
    const cardsLength = useRef<number>(0);
    const outViewportCardsLength = useRef<number>(0);
    const buttons = useRef<ChildNode[]>([]);

    const filteredBarbers = useMemo(() => {
        return barbers.filter(barber => barber.name !== 'Blerton Selmani');
    }, [barbers]);

    useEffect(() => {
        if (cardsRef?.current) {
            cardsLength.current = cardsRef.current?.childNodes.length;
            outViewportCardsLength.current = cardsLength.current - 1; // Deducting 1 because in the end we want to have the last one in the viewport when its done
            setClicksLeft(outViewportCardsLength.current);

            const cards: ChildNode[] = [];
            cardsRef.current?.childNodes.forEach(card => {
                cards.push(card);
                // @ts-ignore
                const { clientWidth } = card;
                if (!elementWidth) setElementWidth(clientWidth);
            });
            setCards(cards);
            setAdditionalMargin(() => {
                if (window.innerWidth <= 900) return 80;
                return 120;
            });
        }
    }, [cardsRef, elementWidth, barbers]);

    const onButtonClick = (e: React.MouseEvent<HTMLElement>, side: 'left' | 'right') => {
        // @ts-ignore
        if (!buttons.current.length) buttons.current = e.currentTarget.parentNode.childNodes;
        if (
            (side === 'right' && clicksLeft > 0) ||
            (side === 'left' && clicksLeft !== outViewportCardsLength.current)
        ) {
            const x =
                side === 'right'
                    ? -(elementWidth + additionalMargin) * translateItems
                    : -(elementWidth + additionalMargin) * (translateItems - 2);

            // Animate cards
            const tl = gsap.timeline();
            tl.addLabel('start')
                .to(cards, { x, ease: 'power3.out' }, 'start')
                .add(() => {
                    const updatedClicks = clicksLeft + updateClicks;

                    updatedClicks < outViewportCardsLength.current
                        ? gsap.to(buttons.current[0], { opacity: '1' })
                        : gsap.to(buttons.current[0], { opacity: '.5' });
                    updatedClicks === 0
                        ? gsap.to(buttons.current[1], { opacity: '.5' })
                        : gsap.to(buttons.current[1], { opacity: '1' });
                }, 'start');

            const updateClicks = side === 'right' ? -1 : 1;
            setClicksLeft(cur => cur + updateClicks);
            setTranslateItems(cur => cur - updateClicks);
        }
    };

    return (
        <section className="barbers" id="berberat">
            <NavigableHeader onButtonClick={onButtonClick} extendedClass="barbers__heading" />

            <div
                className={`barbers__cards ${!overlay ? 'barbers__cards--no-overlay' : ''}`}
                ref={cardsRef}
            >
                {filteredBarbers.map(barber => (
                    <BarberCard
                        key={barber.name}
                        src={barber.imgUrl}
                        name={barber.name}
                        rank={barber.rank}
                        rating={barber.rating}
                        onClick={() => setActiveBarber(barber)}
                    />
                ))}
            </div>
            {overlay && (
                <div className="barbers__cards-overlay">
                    <Button onClick={() => setOverlay(false)} type={ButtonTypes.outlinedLight}>
                        Shiko me shume
                    </Button>
                </div>
            )}
            {activeBarber && (
                <BarberModal barber={activeBarber} onClose={() => setActiveBarber(undefined)} />
            )}
        </section>
    );
};

export { Barbers };
