import { BaseAction } from 'sagas/base/base.types';

export function actionCreator<T extends BaseAction>(type: T['type']) {
    return (payload: T['payload'], resolve?: T['resolve'], reject?: T['reject']) => ({
        type,
        payload,
        resolve,
        reject
    })
};
