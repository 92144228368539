import axios from 'axios';
import { API_ENDPOINT } from 'config/app';

// Configure axios defaults
axios.defaults.baseURL = API_ENDPOINT;

export const setupAxios = () => {
    axios.interceptors.request.use((config) => {
        const token = window.localStorage.getItem('auth:token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    })
};

export { axios };