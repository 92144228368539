import { BaseAction } from '../base/base.types';
import { Product } from 'resources/types';

/**
 * Products Init State
 */
export type ProductsInitState = {
    items: Product[],
    total: number
}

/**
 * Products Action Types
 */
export enum ProductsActionTypes {
    REQUEST = '@app/products/REQUEST',
    SUCCESS = '@app/products/SUCCESS',
    FAILURE = '@app/products/FAILURE',
};

/**
 * Products Actions
 */
export type ProductsRequestAction = BaseAction & {
    type: ProductsActionTypes.REQUEST,
    payload: { page: number }
}
export type ProductsSuccessAction = BaseAction & {
    type: ProductsActionTypes.SUCCESS,
    payload: { items: Product[], total: number }
}
export type ProductsFailureAction = BaseAction & {
    type: ProductsActionTypes.FAILURE,
    payload: {}
}

/**
 * All Products Actions
 */
export type ProductsActions =
    ProductsRequestAction
    | ProductsSuccessAction
    | ProductsFailureAction;