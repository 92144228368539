import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { shoppingBagUpdateActions } from 'sagas/shopping-bag/shopping-bag-update';
import { ShoppingBagDeleteRequestAction, ShoppingBagUpdateRequestAction } from 'sagas/shopping-bag/shopping-bag.types';

import { dispatchWithPromise } from 'utils/dispatch-with-promise';

import { ShoppingBagProductProps } from './ShoppingBagProduct.types';

import { Image } from 'components/atoms/image/Image';
import TrashIcon from 'static/svgs/trash-icon.svg';
import { shoppingBagDeleteActions } from 'sagas/shopping-bag/shopping-bag-delete';

const ShoppingBagProduct: FC<ShoppingBagProductProps> = ({ itemId, src, title, price, stock, quantity }) => {
    const dispatch = useDispatch();

    const _options = [];
    for (let i = 1; i <= stock; i += 1) {
        _options.push(<option key={`option-${Math.random() + i}`} value={i}>{i}</option>);
    }

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        dispatchWithPromise<ShoppingBagUpdateRequestAction>(dispatch, shoppingBagUpdateActions.request, {
            itemId,
            quantity: parseInt(e.target.value)
        })
    }

    const onClick = () => {
        // Delete cart item
        dispatchWithPromise<ShoppingBagDeleteRequestAction>(dispatch, shoppingBagDeleteActions.request, {
            itemId
        })
    }

    return (
        <div className="shopping-bag-product">
            <figure className="shopping-bag-product__img">
                <Image src={src} alt="shopping-bag-product" />
            </figure>

            <div className="shopping-bag-product__main">
                <p>{title}</p>
                <span className="shopping-bag-product__price">{price}EU</span>

                <div className="shopping-bag-product__actions">
                    <div className="shopping-bag-product__amount">
                        <select name="amount" value={quantity} onChange={onChange}>
                            {_options}
                        </select>
                    </div>

                    <button onClick={onClick}>
                        <img src={TrashIcon} alt="Trash" />
                        Fshij
                    </button>
                </div>
            </div>
        </div>
    )
};

export { ShoppingBagProduct };