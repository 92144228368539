import axios from 'axios';
import { takeLatest, put } from 'redux-saga/effects';
import { actionCreator } from 'utils/action-creator'
import { BookingAddActionTypes, BookingAddRequestAction, BookingAddSuccessAction, BookingAddFailureAction } from './booking.types';

/**
 * Redux Actions
 */
export const bookingAddActions = {
    request: actionCreator<BookingAddRequestAction>(BookingAddActionTypes.REQUEST),
    success: actionCreator<BookingAddSuccessAction>(BookingAddActionTypes.SUCCESS),
    failure: actionCreator<BookingAddFailureAction>(BookingAddActionTypes.FAILURE)
};

/**
 * Saga Functions
 */
export const sagas = {
    *request({ payload, resolve, reject }: BookingAddRequestAction) {
        try {
            const res = yield axios.post(`/bookings`, payload);
            yield put(bookingAddActions.success(null));
            resolve!(res.data);
        } catch (err) {
            yield put(bookingAddActions.failure(err.response.data.errors));
            reject!(err);
        }
    }
}

/**
 * Saga Watchers
 */
export const bookingAddWatchers = function* () {
    yield takeLatest(BookingAddActionTypes.REQUEST, sagas.request)
}
