import React from 'react';

import { HeadingTypes } from 'enums/Heading.enums';
import { ButtonTypes } from 'enums/Button.enums';

import { Button } from 'components/atoms/button/Button';
import { Heading } from 'components/atoms/heading/Heading'
import { Certification } from 'components/atoms/certification/Certification';

import AboutImage from 'static/images/about-image.jpg';

const About = () => {
    return (
        <section className="about" id="rreth ne">
            <div className="about__main">
                <Heading type={HeadingTypes.secondary}>
                    {/* a <Certification />
                        certified barbershop */}
                        berber të çertifikuar nga <Certification />
                </Heading>
                <p className="about__description">
                    Trend salon ka filluar rrugëtimin prej vitit 2002. Ne në trend besojm që baza për një prerje të përsosur është teknika e shkëlqyeshme dhe një merak per të qenë qdo ditë edhe më i mirë. Ne hulumtojmë vazhdimisht për të pasur njohuri gjithëpërfshirse të formës së kokës, strukturës dhe llojit të flokut. Nga ana e teknikave të punës, jemi munduar që të bëjmë lidhje në mes të barberis precize dhe parukerisë moderne vetëm për të arritur pamje të përpikt të imazhit tuaj personal, qoftë i përjetshem, klasik apo i trendit te fundit.
                </p>

                <div className="about__btn-group">
                    <Button to="barbers" link="scroll" type={ButtonTypes.dark}>Rezervo termin</Button>
                    <Button to="products" link="scroll" type={ButtonTypes.dark}>Shiko produktet</Button>
                </div>
            </div>

            <figure className="about__image">
                <img src={AboutImage} alt="About section" />
            </figure>
        </section>
    )
};

export { About };