import React, { FC } from 'react';

import { BarberCardProps } from './BarberCard.types';
import { ButtonTypes } from 'enums/Button.enums';

import { Button } from 'components/atoms/button/Button';
// import { Image } from 'components/atoms/image/Image';

import RatingIcon from 'static/svgs/rating-icon.svg';

const BarberCard: FC<BarberCardProps> = ({ src, name, rank, rating, onClick }) => (
    <div className="barber-card">
        <div className="barber-card__content">
            {name === 'Kushtrim Sadiku' && (
                <span className="barber-card__badge">Vetem mbi 14 vjeç</span>
            )}
            <p className="barber-card__name">{name}</p>
            <span className="barber-card__rank">{rank}</span>
            <span className="barber-card__rating">
                <img src={RatingIcon} alt="Star" />
                <span>{rating}</span>
            </span>

            <Button type={ButtonTypes.smallPr} onClick={onClick}>
                Rezervo
            </Button>
        </div>

        <figure className="barber-card__figure">
            {/* <Image src={src} alt="Barbers 1" /> */}
            <img src={src} alt="Barbers 1" />
        </figure>
    </div>
);

export { BarberCard };
