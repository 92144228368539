import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString, { ParsedQuery } from 'query-string';

import { useLoaderSelector } from 'hooks/use-loader-selector';

import { ContainerEnums } from 'enums/Container.enums';
import { ProductsLayoutTypes } from 'enums/Products.enums';

import { productsActions } from 'sagas/products/products';
import { ProductsRequestAction, ProductsActionTypes } from 'sagas/products/products.types';
import { selectProducts } from 'sagas/selectors';

import { dispatchWithPromise } from 'utils/dispatch-with-promise';
import { history } from 'utils/history';

import { Products } from 'components/templates/products/Products';
import { Nav } from 'components/organisms/nav/Nav';
import { Container } from 'components/templates/container/Container';
import { Footer } from 'components/templates/footer/Footer';
import { Loader } from 'components/molecules/loader/Loader';

const AllProducts = () => {
    const params: ParsedQuery<string> = queryString.parse(history.location.search);

    const dispatch = useDispatch();
    const { items, total } = useSelector(selectProducts);
    const [isLoading] = useLoaderSelector(ProductsActionTypes.REQUEST);

    useEffect(() => {
        dispatchWithPromise<ProductsRequestAction>(dispatch, productsActions.request, { page: parseInt(params.page as string) || 1 });
    }, [dispatch, params.page])

    return (
        <>
            {
                isLoading ? <Loader /> :
                    <Container type={ContainerEnums.allProducts}>
                        <div className="all-products">
                            <Nav />
                            <Products items={items} title="Te gjitha produktet" layout={ProductsLayoutTypes.page} total={total} />
                        </div>
                        <Footer />
                    </Container>
            }
        </>
    )
};

export { AllProducts };