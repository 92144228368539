import React, { useState, FC } from 'react';

import { ServicesDropdownProps } from './ServicesDropdown.types';

import SmallDeleteIcon from 'static/svgs/small-delete-icon.svg'

const ServicesDropdown: FC<ServicesDropdownProps> = ({ services, selectedServices, updateSelectedServices }) => {
    const [showList, setShowList] = useState<boolean>(false);

    return (
        <div className="services-dropdown">
            <div className="services-dropdown__container" onClick={() => setShowList(!showList)}>
                <div className="services-dropdown__select">
                    Zgjedh servis
                </div>
                {
                    showList &&
                    <ul className="services-dropdown__list">
                        {services.map((item) =>
                            <li key={`${item.title}-${item.duration}`} onClick={() => updateSelectedServices!(item, 'add')} className={`services-dropdown__item ${selectedServices.includes(item) && 'services-dropdown__item--disabled'}`}>
                                <div>
                                    <p className="services-dropdown__service">{item.title}</p>
                                    <span className="services-dropdown__time">{item.duration}min</span>
                                </div>
                                <p className="services-dropdown__price">{item.price}EU</p>
                            </li>
                        )}
                    </ul>
                }
            </div>

            <div className="services-dropdown__selected-services">
                <ul className="services-dropdown__selected-list">
                    {selectedServices.map(item => (
                        <li key={`${item.title}-${item.price}`} className="services-dropdown__selected-item">
                            <span className="services-dropdown__selected-name">{item.title}</span>
                            <span className="services-dropdown__selected-delete" onClick={() => updateSelectedServices!(item, 'remove')}>
                                <img src={SmallDeleteIcon} alt="Delete" />
                            </span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>

    )
};

export { ServicesDropdown };