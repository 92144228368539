import React, { FC, useState } from 'react';
import { history } from 'utils/history';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, SubmitHandler } from 'react-hook-form';

import { useLoaderSelector } from 'hooks/use-loader-selector';
import { useErrorSelector } from 'hooks/use-error-selector';

import { selectAuth } from 'sagas/selectors';
import { authActions } from 'sagas/auth/auth';
import { AuthRequestAction } from 'sagas/auth/auth.types';
import { shoppingBagAddActions } from 'sagas/shopping-bag/shopping-bag-add';
import { ShoppingBagAddRequestAction, ShoppingBagAddActionTypes } from 'sagas/shopping-bag/shopping-bag.types';

import { dispatchWithPromise } from 'utils/dispatch-with-promise';

import { ProductModalProps, ProductModalFormValues } from './ProductModal.types';
import { ButtonTypes } from 'enums/Button.enums';
import { ErrorSizeTypes } from 'enums/Error.enums';

import { Button } from 'components/atoms/button/Button'
import { Error } from 'components/atoms/error/Error';

import { QuantitySelector } from 'components/atoms/quantity-selector/QuantitySelector';
import { FormGroup } from 'components/molecules/form-group/FormGroup';
import { Modal } from 'components/templates/modal/Modal';

const ProductModal: FC<ProductModalProps> = ({ item, onClose }) => {
    const [quantity, setQuantity] = useState<number>(1);
    const [showForm, setShowForm] = useState<boolean>(false);
    const [isLoading] = useLoaderSelector(ShoppingBagAddActionTypes.REQUEST);
    const [isError] = useErrorSelector(ShoppingBagAddActionTypes.FAILURE);

    const { user, isAuthenticated } = useSelector(selectAuth);

    const dispatch = useDispatch();

    const { register, handleSubmit, errors } = useForm<ProductModalFormValues>({
        mode: 'onTouched',
        defaultValues: {
            name: user?.name || '',
            phone: user?.phone || '',
            email: user?.email || ''
        }
    });

    const onClick = () => {
        if (!isAuthenticated) {
            setShowForm(true)
        } else {
            dispatchWithPromise<ShoppingBagAddRequestAction>(
                dispatch,
                shoppingBagAddActions.request,
                { productId: item.id, quantity }
            ).then(() => history.push('/shopping-bag'))
        }
    }

    const onSubmit: SubmitHandler<ProductModalFormValues> = (values) => {
        dispatchWithPromise<AuthRequestAction>(dispatch, authActions.request, {
            name: values.name,
            phone: values.phone,
            email: values.email || undefined
        }).then(() => {
            dispatchWithPromise<ShoppingBagAddRequestAction>(dispatch, shoppingBagAddActions.request, {
                productId: item.id,
                quantity
            }).then(() => history.push('/shopping-bag'));
        })
    }

    return (
        <Modal onClose={onClose}>
            <div className="product-modal">
                <div className="product-modal__picture">
                    <img src={item.imgUrl} alt="Product" />
                </div>
                <div className="product-modal__details">
                    {
                        showForm
                            ? <div className="product-modal__content">
                                <form >
                                    <h4 className="product-modal__form-heading">Informacioni juaj</h4>

                                    <div className="product-modal__form-group-cont">
                                        <FormGroup
                                            label="Emri i plotë"
                                            name="name"
                                            type="text"
                                            ref={register({ required: 'Emri është i nevojshëm' })}
                                            errors={errors}
                                        />
                                        <FormGroup
                                            label="Numri i telefonit"
                                            name="phone"
                                            type="text"
                                            ref={register({
                                                required: 'Numri i telefonit është i nevojshëm',
                                                minLength: { value: 9, message: 'Numri duhet të jete mes 9 dhe 13 karaktereve' },
                                                maxLength: { value: 13, message: 'Numri duhet të jete mes 9 dhe 13 karaktereve' },
                                            })}
                                            errors={errors}
                                        />
                                        <FormGroup
                                            label="Email (Opsionale)"
                                            name="email"
                                            type="email"
                                            ref={register({ pattern: { value: /^\S+@\S+$/i, message: 'Email është e pasaktë' } })}
                                            errors={errors}
                                        />

                                        <div className="product-modal__btn-group">
                                            <Button onClick={handleSubmit(onSubmit)} type={ButtonTypes.primary}>Kompleto</Button>
                                            <Button onClick={() => setShowForm(false)} type={ButtonTypes.outlinedLight}>Kthehu</Button>
                                        </div>
                                        {isError && <Error size={ErrorSizeTypes.medium} message="Produkti nuk ka mundur të shtohet në shportë" />}
                                    </div>
                                </form>
                            </div>
                            : <>
                                <div className="product-modal__content">
                                    <p className="product-modal__price">{item.price} EU</p>
                                    <h4 className="product-modal__title">{item.title}</h4>
                                    <p className="product-modal__brand">{item.brand}</p>
                                    <p className="product-modal__description">{item.description}</p>
                                </div>

                                <div className="product-modal__actions">
                                    <Button disabled={item.stock <= 0} onClick={onClick} type={ButtonTypes.primary}>{!isLoading ? 'Shto në shportë' : 'Përpunuar...'}</Button>
                                    {item.stock > 0 && <QuantitySelector quantity={quantity} setQuantity={(value) => setQuantity(value)} stock={item.stock} />}
                                </div>
                                {isError && <Error size={ErrorSizeTypes.medium} message="Produkti nuk ka mundur të shtohet në shportë" />}
                            </>
                    }
                </div>
            </div>
        </Modal>
    )
}

export { ProductModal };