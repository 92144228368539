import { BaseAction } from '../base/base.types';
import { User } from 'resources/types';

/**
 * Auth Init State
 */
export type AuthInitState = {
    isAuthenticated: boolean;
    user: User
}

/**
 * Auth Action Types
 */
export enum AuthActionTypes {
    REQUEST = '@app/auth/REQUEST',
    REGISTER = '@app/auth/REGISTER',
    SUCCESS = '@app/auth/SUCCESS',
    FAILURE = '@app/auth/FAILURE',
};

export enum AuthInitActionTypes {
    REQUEST = '@app/auth/init/REQUEST',
    SUCCESS = '@app/auth/init/SUCCESS',
    FAILURE = '@app/auth/init/FAILURE'
};

/**
 * Auth Actions
 */
export type AuthRequestAction = BaseAction & {
    type: AuthActionTypes.REQUEST,
    payload: {
        name: string,
        phone: string,
        email?: string,
        address?: string,
        city?: string
    }
}
export type AuthRegisterAction = BaseAction & {
    type: AuthActionTypes.REGISTER,
    payload: {
        token: string
    }
}
export type AuthSuccessAction = BaseAction & {
    type: AuthActionTypes.SUCCESS,
    payload: { user: User }
}
export type AuthFailureAction = BaseAction & {
    type: AuthActionTypes.FAILURE,
    payload: {}
}

export type AuthInitRequestAction = BaseAction & {
    type: AuthInitActionTypes.REQUEST,
    payload: {}
}
export type AuthInitSuccessAction = BaseAction & {
    type: AuthInitActionTypes.SUCCESS,
    payload: {}
}
export type AuthInitFailureAction = BaseAction & {
    type: AuthInitActionTypes.FAILURE,
    payload: {}
}

/**
 * All Auth Actions
 */
export type AuthActions =
    AuthRequestAction
    | AuthSuccessAction
    | AuthFailureAction
    | AuthInitRequestAction
    | AuthInitSuccessAction
    | AuthInitFailureAction;