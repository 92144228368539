import { actionCreator } from 'utils/action-creator';
import { BaseLoadersActionTypes, BaseAction, CatchersInitState } from './base.types';

/**
 * Init State
 */
export const initState: CatchersInitState = {};

/**
 * Defualt Reducer
 *
 * @param state
 * @param action
 */
export const loadersReducer = (state = initState, { type }: BaseAction) => {
    /*
     * Reset State
     */
    if (type === BaseLoadersActionTypes.RESET) {
        return initState;
    }

    /*
     * Remove Single Action
     */
    if (type === BaseLoadersActionTypes.REMOVE) {
        const { [type]: value, ...removedState } = state;
        return removedState;
    }

    const isSuccess = type.includes('SUCCESS');
    const isRequest = type.includes('REQUEST');
    const isFailure = type.includes('FAILURE');

    if (isRequest) {
        return { ...state, [type]: true };
    }

    if (isSuccess || isFailure || !isRequest) {
        return { ...state, [type.replace(/SUCCESS|FAILURE/, 'REQUEST')]: false };
    }

    return state;
}

/**
 * Redux Actions
 */
export const loadersActions = {
    remove: actionCreator(BaseLoadersActionTypes.REMOVE),
    reset: actionCreator(BaseLoadersActionTypes.RESET)
};
