import { combineReducers } from 'redux';

import { authReducer as auth } from 'sagas/auth/auth'
import { errorsReducer as errors } from 'sagas/base/errors';
import { loadersReducer as loaders } from 'sagas/base/loaders';
import { landingReducer as landing } from 'sagas/landing/landing';
import { bookingReducer as booking } from 'sagas/booking/booking';
import { productsReducer as products } from 'sagas/products/products';
import { shoppingBagReducer as shoppingBag } from 'sagas/shopping-bag/shopping-bag';

import { AuthInitState } from 'sagas/auth/auth.types';
import { CatchersInitState } from 'sagas/base/base.types';
import { LandingInitState } from 'sagas/landing/landing.types';
import { BookingInitState } from 'sagas/booking/booking.types';
import { ProductsInitState } from 'sagas/products/products.types';
import { ShoppingBagInitState } from 'sagas/shopping-bag/shopping-bag.types';

export interface StoreState {
    auth: AuthInitState,
    errors: CatchersInitState,
    loaders: CatchersInitState,
    landing: LandingInitState,
    booking: BookingInitState,
    products: ProductsInitState,
    shoppingBag: ShoppingBagInitState
}

export const rootReducer = combineReducers<StoreState>({
    auth,
    errors,
    loaders,
    landing,
    booking,
    products,
    shoppingBag
});