import React, { FC, forwardRef } from 'react';

import { FormGroupProps } from './FormGroup.types';

import { Error } from 'components/atoms/error/Error'
import { Input } from 'components/atoms/input/Input';
import { Select } from 'components/atoms/select/Select';
import { ServicesDropdown } from 'components/molecules/services-dropdown/ServicesDropdown';

const FormGroup: FC<FormGroupProps> = forwardRef(({ name, label, type, options, selectedOption, services, selectedServices, updateSelectedServices, onChange, errors, disabled }, ref) => {
    const input = type === 'select'
        ? <Select name={name} options={options} selectedOption={selectedOption} ref={ref} onChange={onChange} disabled={disabled} />
        : type === 'service'
            ? <ServicesDropdown services={services!} selectedServices={selectedServices!} updateSelectedServices={updateSelectedServices!} />
            : <Input type={type} name={name} ref={ref} onChange={onChange} disabled={disabled} />

    return (
        <div className="form-group">
            <label htmlFor={name}>{label}</label>
            {input}
            {
                errors && errors[name]?.message &&
                <Error message={errors[name]?.message} />
            }
        </div>
    )
});

export { FormGroup };