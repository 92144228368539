import React, { FC } from 'react';

// import { Image } from 'components/atoms/image/Image';

import { ProductCardProps } from './ProductCard.types';

const ProductCard: FC<ProductCardProps> = ({ src, title, price, onClick, stock }) => {
    return (
        <div className="product-card" onClick={onClick}>
            <div className="product-card__background"></div>
            <figure className="product-card__figure">
                {/* <Image src={src} alt="Product 1" /> */}
                <img src={src} alt="Product 1" />
            </figure>
            <div className="product-card__content">
                <h5 className="product-card__title" title={title}>{title}</h5>
                {
                    stock <= 0
                        ? <span className="product-card__sold-out">E shitur</span>
                        : <span className="product-card__price">{price}EU</span>
                }
            </div>
        </div>
    )
};

export { ProductCard };