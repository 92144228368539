import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useLoaderSelector } from 'hooks/use-loader-selector';

import { landingActions } from 'sagas/landing/landing';
import { LandingRequestAction, LandingActionTypes } from 'sagas/landing/landing.types';
import { selectLanding } from 'sagas/selectors';

import { history } from 'utils/history';
import { dispatchWithPromise } from 'utils/dispatch-with-promise';

import { ContainerEnums } from 'enums/Container.enums';
import { ProductsLayoutTypes } from 'enums/Products.enums';

import { Container } from 'components/templates/container/Container';
import { Gallery } from 'components/templates/gallery/Gallery';
import { Header } from 'components/templates/header/Header';
import { About } from 'components/templates/about/About';
// import { Testimonials } from 'components/templates/testimonials/Testimonials';
import { Barbers } from 'components/templates/barbers/Barbers';
import { Products } from 'components/templates/products/Products';
import { Footer } from 'components/templates/footer/Footer';
import { Loader } from 'components/molecules/loader/Loader';

import { Barber } from 'resources/types';

const Landing = () => {
    const dispatch = useDispatch();
    const [isLoading] = useLoaderSelector(LandingActionTypes.REQUEST);
    const { barbers, products } = useSelector(selectLanding);
    const [orderedBarbers, setOrderedBarbers] = useState<Barber[]>([]);

    useEffect(() => {
        if (history.location.hash) {
            document.querySelector(history.location.hash)?.scrollIntoView({
                behavior: 'smooth',
            });
        }

        dispatchWithPromise<LandingRequestAction>(dispatch, landingActions.request, null);
    }, [dispatch]);

    useEffect(() => {
        if (barbers.length) {
            const barberIds = [6, 5, 4, 1, 3, 2];
            const barberList: Barber[] = [];
            barberIds.forEach(index => {
                const barber = barbers.find(barber => barber.id === index);
                if (barber) barberList.push(barber);
            });

            setOrderedBarbers(barberList);
        }
    }, [barbers]);

    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <Container type={ContainerEnums.landing}>
                    <Gallery />

                    <Header />

                    <About />

                    {/* <Testimonials /> */}

                    <Barbers barbers={orderedBarbers} />

                    <Products
                        items={products}
                        title="Produktet"
                        layout={ProductsLayoutTypes.section}
                    />

                    <Footer />
                </Container>
            )}
        </>
    );
};

export { Landing };
